import styled from 'styled-components';

const SkipLink = styled.a`
  padding: 8px;
  position: absolute;
  height: 0;
  width: 0;
  overflow: hidden;
  z-index: 99;

  &:focus {
    position: static;
    width: 100%;
    height: auto;
    overflow: visible;
    background-color: #bd13b8;
    color: #ffffff;
  }
`;

export const SkipToMain = () => {
  return <SkipLink href="#main">Անցնել հիմնական բովանդակությանը</SkipLink>;
};
