import { createBrowserRouter } from 'react-router-dom';
import { PublicRoutes } from './components/routes/public-route';
import { PrivateRoute } from './components/routes/private-route';
import { PATHS } from './helpers/constants';
// import { SignIn } from './pages/sign-in';
import { Application } from './pages/application';
import { ApplicationLayout } from './components/layouts/application/application-layout';
import { ApplicationPrintLayout } from './components/layouts/application/application-print-layout';
import { SignInApp } from './pages/user-app/sign-in';
import { MainLayout } from './components/layouts/main-layout';
import { Notifications } from './pages/notifications';
import { Account } from './pages/account';
import { ChangePassword } from './pages/change-password';
import { Applications } from './pages/applications';
import { OtPassword } from './pages/ot-password';
import { Services } from './pages/services';
import { Settings } from './pages/settings';
import { Calendar } from './pages/calendar';
import { SelfAssessment } from './pages/self-assessment';
import { LayoutUser } from './components/layouts/sign-in/layout-user';
import { EmailSettings } from './pages/settings/email';
import { Error403 } from './pages/error-pages/error-403';
import { ApplicationView } from './pages/application-view';
import { ApplicationNonReiable } from './pages/application-non-reliable';
import { MainRoute } from './components/routes/main-route';
import { AppForgotPass } from './pages/user-app/forgot-password';
import { RecoverPassword } from './pages/recover-password';
import { Home } from './pages/personal-cabinet/home';
import { MyServices } from './pages/services/my-services';
import { ArcaxSupport } from 'pages/services/arcax-support';

import { Benefit } from './pages/benefit';
import { ApplicationPrint } from './pages/application-print/applications';
import { FamilyAgreementPrint } from './pages/application-print/family-agreement-print';
import { NonReliableApplicationPrint } from './pages/application-print/non-reliable-application';
import { DecisionPrint } from './pages/application-print/decision';
import { PersonalServicesView } from 'pages/personal-services/personal-services-view';
import { OAuthCallback } from './components/oAuth-callback';
import { DisabilityType } from './pages/disability/disability-type';
import { DisabilityLayout } from 'components/layouts/disability/disability-layout';
import { DisabilityApplication } from 'pages/disability-application';
import { HavingBadCreditApplication } from './pages/having-bad-credit-application';
import { DifficultSituationApplicationSelection } from './pages/difficult-situation-application-selection';
import { CompanyRoute } from 'components/routes/company-route';
import { PersonRoute } from 'components/routes/person-route';
import { RapidNeed } from 'pages/rapid-need';

import { Authority } from 'pages/authorities';

import { OfferingSupport } from 'pages/offering-support';
import { DonorAgreement } from 'pages/donor-agreement';
import { AgreementApplications } from 'pages/agreements';

import { AddAuthorizedPerson } from 'pages/create-authority';
import { Agreement } from 'pages/agreements/agreement-view';
import { Maps } from 'pages/maps';
import { AuthoritiesAddress } from './pages/applications/components/authorities-address';
import { ApplicationLayout as ServiceLayout } from './components/layouts/application/default-layout';
import { ApplyForTheProgram } from './pages/having-bad-credit-application/apply_for_the_program';
import { SubmitApplicationInfo } from './pages/having-bad-credit-application/submit_application_info';
import { Subservices } from 'pages/services/subservices';

export const router = createBrowserRouter([
  {
    path: PATHS.OAUTHCALLBACK,
    element: <OAuthCallback />,
  },
  {
    element: <MainRoute />,
    children: [
      {
        element: <PublicRoutes />,
        children: [
          {
            element: <LayoutUser />,
            children: [
              {
                path: PATHS.ROOT,
                element: <MyServices />,
              },
              {
                path: PATHS.SUB_SERVICES,
                element: <Subservices />,
              },
              {
                path: PATHS.SUPPORT_APPLICATIONS,
                element: <Subservices />,
              },
              {
                path: PATHS.ARCAX_SUPPORT,
                element: <ArcaxSupport />,
              },
              {
                path: PATHS.SIGN_IN,
                element: <SignInApp />,
              },
              // having bad credit
              {
                path: PATHS.DIFFICULT_SITUATION_APPLICATION_SELECTION_PUBLIC,
                element: <DifficultSituationApplicationSelection />,
              },
              {
                path: PATHS.CHANGE_PASSWORD,
                element: <ChangePassword />,
              },
              {
                path: PATHS.ONE_TIME_PASSWORD,
                element: <OtPassword />,
              },
              {
                path: PATHS.CHANGE_EMAIL,
                element: <EmailSettings />,
              },
              {
                path: PATHS.ERROR_403,
                element: <Error403 />,
              },
              {
                path: PATHS.FORGOT_PASSWORD,
                element: <AppForgotPass />,
              },
              {
                path: PATHS.RECOVER_PASSWORD,
                element: <RecoverPassword />,
              },
            ],
          },
          {
            path: PATHS.APPLY_FOR_THE_PROGRAM,
            element: <ApplyForTheProgram />,
          },
          {
            path: PATHS.SUBMIT_APPLICATION_INFO,
            element: <SubmitApplicationInfo />,
          },
        ],
      },
      {
        element: <PrivateRoute />,
        children: [
          {
            element: <MainLayout />,
            children: [
              // Person routes
              {
                element: <PersonRoute />,
                children: [
                  // Benefit
                  {
                    path: PATHS.BENEFIT,
                    element: <Benefit />,
                  },

                  {
                    element: <ApplicationLayout hideHeader />,
                    children: [
                      {
                        element: <Applications />,
                        children: [
                          {
                            path: PATHS.APPLICATIONS_VIEW,
                            element: <ApplicationView />,
                          },
                          {
                            path: PATHS.APPLICATION_NON_RELIABLE_VIEW,
                            element: <ApplicationNonReiable />,
                          },
                          {
                            path: PATHS.APPLICATIONS_INCOMPLETE_VIEW,
                            element: <ApplicationView />,
                          },
                        ],
                      },
                    ],
                  },
                  // Calendar
                  {
                    path: PATHS.CALENDAR,
                    element: <Calendar />,
                  },
                  // Self Assessment
                  {
                    path: PATHS.SELF_ASSESSMENT,
                    element: <SelfAssessment />,
                  },
                  // disability_type
                  {
                    path: PATHS.DISABILITY_TYPE,
                    element: <DisabilityType />,
                  },
                  // having bad credit
                  {
                    path: PATHS.DIFFICULT_SITUATION_APPLICATION_SELECTION,
                    element: <DifficultSituationApplicationSelection />,
                  },
                  {
                    path: PATHS.APPLICATION_BAD_CREDIT,
                    element: <HavingBadCreditApplication />,
                  },
                  // rapid need
                  {
                    path: PATHS.RAPID_NEED,
                    element: <RapidNeed />,
                  },
                  {
                    path: PATHS.RAPID_NEED_VIEW,
                    element: <RapidNeed />,
                    handle: { type: 'view' },
                  },
                  {
                    path: PATHS.RAPID_NEED_EDIT,
                    element: <RapidNeed />,
                    handle: { type: 'edit' },
                  },
                ],
              },
              // Compnay routes
              {
                element: <CompanyRoute />,
                children: [
                  {
                    path: '/company',
                    element: <p>Company routes here</p>,
                  },
                  // Authority
                  {
                    path: PATHS.AUTHORITY,
                    element: <Authority />,
                  },
                  {
                    path: PATHS.ADD_AUTHORITY,
                    element: <AddAuthorizedPerson />,
                    handle: { type: 'fill' },
                  },
                  {
                    path: PATHS.EDIT_AUTHORITY,
                    element: <AddAuthorizedPerson />,
                    handle: { type: 'edit' },
                  },
                  {
                    path: PATHS.VIEW_AUTHORITY,
                    element: <AddAuthorizedPerson />,
                    handle: { type: 'view' },
                  },
                ],
              },
              //* Common routes for both user types
              // agreement applications
              {
                path: PATHS.AGREEEMENTS,
                element: <AgreementApplications />,
              },
              // view agreement application
              {
                path: PATHS.AGREEMENT_APPLICATION_VIEW,
                element: <Agreement />,
              },
              // Applications
              {
                path: PATHS.APPLICATIONS,
                element: <Applications />,
              },
              // Offering supprot / Donor
              {
                path: PATHS.OFFERING_SUPPORT,
                element: <OfferingSupport />,
                handle: { type: 'fill' },
              },
              {
                path: PATHS.OFFERING_SUPPORT_VIEW,
                element: <OfferingSupport />,
                handle: { type: 'view' },
              },
              {
                path: PATHS.OFFERING_SUPPORT_EDIT,
                element: <OfferingSupport />,
                handle: { type: 'edit' },
              },
              // Agreement
              {
                path: PATHS.CREATE_DONOR_AGREEMENT,
                element: <DonorAgreement />,
                handle: { type: 'create' },
              },
              // Home Page
              {
                path: PATHS.HOME,
                element: <Home />,
              },
              // Notification
              {
                path: PATHS.NOTIFICATIONS,
                element: <Notifications />,
              },
              // Account
              {
                path: PATHS.ACCOUNT,
                element: <Account />,
              },
              // Services
              {
                path: PATHS.SERVICES,
                element: <Services />,
              },
              {
                path: PATHS.PRIVATE_SUBSERVICES,
                element: <Subservices />,
              },
              {
                path: PATHS.SUPPORT_APPLICATIONS,
                element: <Subservices />,
              },
              // Settings
              {
                path: PATHS.SETTINGS,
                element: <Settings />,
              },

              {
                element: <ApplicationLayout hideHeader />,
                children: [
                  {
                    element: <Services />,
                    children: [
                      {
                        path: PATHS.SERVICES_VIEW,
                        element: <PersonalServicesView />,
                      },
                    ],
                  },
                ],
              },
              {
                path: PATHS.MAPS,
                element: <Maps />,
              },
            ],
          },
          {
            element: <ApplicationLayout text={'Դիմում ընտանիքի անապահովության նպաստ  ստանալու մասին'} />,
            children: [
              {
                path: PATHS.APPLICATION,
                element: <Application />,
              },
            ],
          },
          {
            element: <ServiceLayout text={'ՀՀ ՊԵՏԱԿԱՆ ԳԵՐԱՏԵՍՉՈՒԹՅՈՒՆՆԵՐԻ ՀԱՍՑԵՆԵՐ'} />,
            children: [
              {
                path: PATHS.AUTHORITIES_ADDRESS,
                element: <AuthoritiesAddress />,
              },
            ],
          },
          {
            element: <ApplicationLayout isEdit />,
            children: [
              {
                path: PATHS.APPLICATIONS_UPDATE,
                element: <Application />,
                handle: 'edit',
              },
            ],
          },
          {
            element: <ApplicationPrintLayout />,
            children: [
              // Prints
              {
                path: PATHS.APPLICATIONS_PRINT,
                element: <ApplicationPrint />,
              },
              {
                path: PATHS.FAMILY_AGREEMENT_PRINT,
                element: <FamilyAgreementPrint />,
              },
              {
                path: PATHS.NON_RELIABLE_AGREEMENT_PRINT,
                element: <NonReliableApplicationPrint />,
              },
              {
                path: PATHS.DECISION_PRINT,
                element: <DecisionPrint />,
              },
            ],
          },
          {
            element: <DisabilityLayout />,
            children: [
              {
                path: PATHS.DISABILITY_APPLICATION,
                element: <DisabilityApplication />,
              },
            ],
          },
        ],
      },
    ],
  },
]);
