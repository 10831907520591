import { Row, Col, Typography } from 'antd';

import { Text } from "../../../components/typography"

export const TrusteeApplicationsPrint = () => {
    return (
        <>
            <Row justify="center" gutter={[16, 16]}>
                <Col><Text style={{ color: '#3B3F5F' }}>Հավաստում</Text>
                </Col>
            </Row>
            <Text>
                Խնդրում եմ խնամարկյալ(ներ)իս հաշվառել ընտանիքի սոցիալական գնահատման և սոցիալական ծառայությունների տեղեկատվական համակարգում: Կից ներկայացնում եմ իմ՝ որպես խնամակալի համաձայնության հավաստիացումը։Ես՝ ներքոստորագրյալս, համաձայն եմ սույն դիմումի բովանդակությանը, ինչպես նաև ընդունում եմ դիմումում ներկայացված պարտավորությունները։

            </Text>
            <ul>
                <li>
                    համաձայն եմ, որ ՀՀ կառավարության կողմից սահմանված կարգով, առկայության դեպքում, ստուգվեն իմ խնամարկյալ(ներ)ի ընտանեկան, այդ թվում՝ ամուսնական կապերը, եկամուտների(աշխատավարձերի, դրանց հավասարեցված այլ վճարումների, բանկային հաշիվների և ավանդների) և ծախսերի(վարկային պարտավորությունների, սպառած խմելու ջրի, էլեկտրաէներգիայի, բնական գազի քանակի) վերաբերյալ տվյալները, ինչպես նաև ՀՀ կառավարության 2014 թ․ 145 – Ն որոշմամբ սահմանված այլ տվյալներ,
                </li>
                <li>
                    երաշխավորում եմ, որ ընտանիքի սոցիալական գնահատման և սոցիալական ծառայությունների տեղեկատվական համակարգում
                </li>
                <li>
                    հաշվառվելու և հաշվառված լինելու ընթացքում ՀՀ աշխատանքի և սոցիալական հարցերի նախարարության(այսուհետ՝ նախարարություն) միասնական սոցիալական ծառայության տարածքային կենտրոնի(այսուհետ՝ տարածքային կենտրոնի) աշխատողը և ՀՀ օրենսդրությամբ նման իրավասություն ունեցող այլ անձինք իմ խնամարկյալ(ներ)ի կեցության պայմանների ուսումնասիրման նպատակով կարող են առանց խոչընդոտների կատարել այս դիմումում ներկայացված և պահանջվող այլ տեղեկությունների հավաստիության ուսումնասիրություն, այդ թվում՝ տնայց,
                </li>
                <li>
                    տեղյակ եմ, որ առաջին տնայցը կատարվելու է դիմումի հաստատումից 15 աշխատանքային օրվա ընթացքում, և և պարտավորվում եմ համագործակցել տնայց իրականացնողի հետ անհրաժեշտ տվյալների լիարժեք հավաքագրման նպատակով,
                </li>
                <li>
                    համաձայն եմ, որ տնայցի ընթացքում տարածքային կենտրոնի աշխատողը լուսանկարի իմ խնամարկյալ(ներ)ի կեցության պայմանների առանձին հատվածներ(առանց լուսանկարելու անձանց դեմքերը), և տեղյակ եմ, որ արված լուսանկարները կիրառվելու են միայն ընտանիքի սոցիալական գնահատման և սոցիալական ծառայությունների տեղեկատվական համակարգում, ինչպես նաև պահվելու և մշակվելու են ՀՀ օրենսդրությամբ սահմանված կարգով,
                </li>
                <li>
                    տեղյակ եմ, որ անապահովության նպաստը(այսուհետ՝ նպաստ) նշանակվում է մինչև դադարեցման հիմքերն առաջանալը, սակայն ոչ ավելի, քան 12 ամիս ժամկետով,
                </li>
                <li>
                    տեղյակ եմ, որ իմ խնամարկյալ(ներ)ի մասին ոչ հավաստի տվյալներ ներկայացնելու կամ դրանք թաքցնելու պատճառով ավել վճարված գումարը ՀՀ օրենսդրությամբ սահմանված կարգով հետ է գանձվելու,
                </li>
                <li>
                    պարտավորվում եմ իմ խնամարկյալ(ներ)ի ընտանիքի կազմի, բնակության հասցեի, եկամուտների, ունեցվածքի և այլ բնույթի փոփոխությունների դեպքում 15 օրվա ընթացքում կատարել փոփոխություններ այս դիմումում կամ դրանց մասին տեղյակ պահել տարածքային կենտրոնին` անհրաժեշտության դեպքում ներկայացնելով նոր դիմում և համապատասխան փաստաթղթեր,
                </li>
                <li>
                    համաձայն եմ, որ իմ խնամարկյալ(ներ)ի նույնականացման տվյալները փոխանցվեն սոցիալական ծառայություններ տրամադրող այն կազմակերպություներին, որոնք սոցիալական դեպքի վարման ժամանակ պետք է աշխատեն վերջինիս հետ, ինչպես նաև օրենսդրությամբ սահմանված սոցիալական աջակցության ծրագրեր (դրամական և ոչ դրամական աջակցություն, արտոնություններ, զեղչեր և այլն) իրականացնող պետական մարմիններին, տեղական ինքնակառավարման մարմիններին, նախարարության հետ կնքված համագործակցության հուշագրերի (պայմանագրերի) հիման վրա՝ այլ կազմակերպությունների:
                </li>
            </ul>
            <Typography.Title level={5}>
                (ներքոնշյալ «համաձայնությունները» բացվում են խնամարկյալի՝ չափահաս լինելու պարագայում)
            </Typography.Title>
            <ul>
                <li>
                    համաձայն եմ, որ ընտանիքի անապահովության գնահատման նպատակով այս դիմումում նշված նույնականացման տվյալների հիման վրա իմ խնամարկյալ(ներ)ի վարկային պարտավորությունների վերաբերյալ տեղեկատվությունը և հաջորդ մեկ տարվա ընթացքում դրան վերաբերող փոփոխությունները նախարարության և «ԱՔՌԱ Քրեդիտ Ռեփորթինգ» ՓԲԸ - ի միջև կնքված պայմանագրով սահմանված կարգով փոխանցվեն նախարարությանը,
                </li>
                <li>
                    համաձայն եմ, որ իմ խնամարկյալ(ներ)ի՝ այս դիմումում նշված նույնականացման տվյալները տրամադրվեն դրույքախաղեր առաջարկող կազմակերպություններին՝ չափահաս անձանց մուտքը խաղադրույքով ծառայություններին արգելափակելու նպատակով:
                </li>
            </ul>
        </>
    )
}













