import { Radio } from 'antd';
import { URL_LEGAL_REPRESENTIVE_TYPES } from '../../../../api/benefit/constants';
import { useGetFfromBenefit } from '../../../../api/benefit/use-get-dictionary';
import { VerticalSpace } from '../../../space/vertical-space';
import { SecondaryText } from '../../../typography';
import { FormItem } from '../../form-item';
import { COLORS } from 'helpers/constants';

export const OtherPersonType = () => {
  const onChange = () => {
    return;
  };
  const { data } = useGetFfromBenefit(URL_LEGAL_REPRESENTIVE_TYPES);
  return (
    <FormItem name={['profileData', 'legalRepresentativeType']} rules={[{ required: true }]}>
      <Radio.Group onChange={onChange}>
        <VerticalSpace>
          {data.map((item) => (
            <Radio value={item.id} key={item.id}>
              <SecondaryText color={COLORS.SECONDARY.OIL}>{item.name}</SecondaryText>
            </Radio>
          ))}
        </VerticalSpace>
      </Radio.Group>
    </FormItem>
  );
};
