import { URL_LOGIN_CODE_NID_USER } from '../../api/auth/constants';
import { PATHS, REQUEST_TYPES } from '../../helpers/constants';
import { useNavigate } from 'react-router-dom';
import { Col, Row, Spin } from 'antd';
import Coat from './images/nationalid.svg';
import { Title } from '../typography';
import { useAuth } from '../../context/auth-context';
import { useMutateUserData } from '../../api/auth/use-mutate-user-data';
import { useEffect } from 'react';

const defaultStyle = {
  marginBottom: '20px',
  padding: '30px 50px',
  textAlign: 'center',
  background: 'rgba(0, 0, 0, 0.05)',
  borderRadius: '4px',
  alignItems: 'center',
  height: '97vh',
};

export const OAuthCallback = () => {
  const navigate = useNavigate();
  const queryParameters = new URLSearchParams(window.location.search);
  const code = queryParameters.get('code');
  const state = queryParameters.get('state');

  const { login } = useAuth();

  const { mutate } = useMutateUserData(URL_LOGIN_CODE_NID_USER, REQUEST_TYPES.POST, {
    onSuccess: (payload) => {
      login(payload.data);
      const creditApplication = JSON.parse(localStorage.getItem('creditApplication'));
      const rapidNeed = JSON.parse(localStorage.getItem('rapidNeed'));
      const supportNeed = JSON.parse(localStorage.getItem('supportNeed'));
      localStorage.setItem('midLogin', true);
      if (creditApplication) {
        setTimeout(() => navigate(PATHS.APPLICATION_BAD_CREDIT), 4000);
      } else if (rapidNeed) {
        setTimeout(() => navigate(PATHS.RAPID_NEED), 4000);
      } else if (supportNeed) {
        setTimeout(() => navigate(PATHS.OFFERING_SUPPORT), 4000);
      } else {
        setTimeout(() => navigate(PATHS.SERVICES), 4000);
      }
    },
    onError: () => {
      navigate(`${PATHS.ROOT}`);
    },
  });

  useEffect(() => {
    mutate({ code: code, state: state });
  }, [code, state, mutate]);

  return (
    <Row justify={'center'} style={defaultStyle}>
      <Spin spinning={true}>
        <Col>
          <img src={Coat} alt={''} style={{ width: '85px' }} />
        </Col>
        <Col>
          <Title style={{ fontSize: '50px' }}>
            National
            <span style={{ color: '#18BBB4' }}>ID</span>
          </Title>
        </Col>
      </Spin>
    </Row>
  );
};
