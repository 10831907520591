import { Radio, Space } from 'antd';
import { FormSectionCard } from '../../../card/form-section-card';
import { FormItem } from '../../form-item';
import { SecondaryText } from '../../../typography';
import { COLORS, DOCUMENT_TYPES } from '../../../../helpers/constants';
import { useApplication } from 'context/applicaton-context';
import { Form } from 'components/form/form';
import { DocumentUpload } from 'components/upload/upload-documets';

export const MariageCertForm = ({ parent = [] }) => {
  const { submittedData, cityzenData } = useApplication();
  const hasMariageCertBefore2014 = Form.useWatch([...parent, 'hasMarriageCertificateBefore2014']);
  const hasFamilyMember = Form.useWatch([...parent, 'hasOtherFamilyMembers']);

  if (!hasFamilyMember) {
    return null;
  }

  return (
    <FormSectionCard title="Ընտանիքում կան 2014 թ․ առաջ ամուսնություն գրանցած անձինք" style={{ display: 'grid' }}>
      <FormItem rules={[{ required: true }]} name={[...parent, 'hasMarriageCertificateBefore2014']}>
        <Radio.Group>
          <Space>
            <Radio value={true}>
              <SecondaryText color={COLORS.PRIMARY.BLUE}>Այո</SecondaryText>
            </Radio>
            <Radio value={false}>
              <SecondaryText color={COLORS.PRIMARY.BLUE}>Ոչ</SecondaryText>
            </Radio>
          </Space>
        </Radio.Group>
      </FormItem>
      {hasMariageCertBefore2014 === true && (
        <DocumentUpload
          tempApplicationId={submittedData.tempApplicationId}
          attachedDocumentTypeId={DOCUMENT_TYPES.MARIAGE_BEFORE_2014}
          attachedDocumentName={'Ամուսնության վկայական'}
          infoText="Խնդրում ենք կցել ամուսնության վկայականը։"
          userData={cityzenData?.citizenInfo}
          propName={'marriageFiles'}
          checksumName={[...parent, 'marriageFilesChecksum']}
        />
      )}
    </FormSectionCard>
  );
};
