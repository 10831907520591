import { Col, Layout, Row } from 'antd';
import { SubHeader } from '../../components/layouts/sub-header';
import { ContentWrapper } from '../../components/layouts/content-wrapper';
import { COLORS } from '../../helpers/constants';
import { Text } from '../../components/typography';
import { useEffect } from 'react';
import styled from 'styled-components';

const ContainerOl = styled.div`
    ol {
        margin-top: 0;
        font-weight: 100;
        margin-left: 0;
        counter-reset: section;
        
        li {
            ol {
                margin-top: 15px;
                padding-left: 0;
            }
        }
    }

    /* First-level list */
    ol > li {
        counter-increment: section;
        list-style: none;
        position: relative;
        margin-top: 15px;
    }

    ol > li::before {
        content: counter(section) ". ";
        font-weight: bold;
        position: absolute;
        left: -25px;
        color: #3B3F5F
    }

    /* Nested second-level list */
    ol ol {
        counter-reset: subsection;
        margin-left: 30px;
    }

    ol ol > li {
        counter-increment: subsection;
        list-style: none;
        position: relative;
    }

    ol ol > li::before {
        content: counter(section) "." counter(subsection) " ";
        font-weight: bold;
        position: absolute;
        left: -35px;
        color: #3B3F5F
    }
`;



export const ApplyForTheProgram = () => {

  useEffect(() => {
    window.scrollTo(40, 40);
  }, []);

  return (
    <Layout style={{ minHeight: '100vh' }}>
      <SubHeader text={'Անհուսալի վարկերի միջոցառում'} />
      <Row justify={'center'}>
        <Col xs={20} sm={20} md={20} lg={12} xl={12}>
          <ContentWrapper
            style={{
              padding: '60px 23px',
              display: 'flex',
              margin: '30px 0',
              alignItems: 'center',
              cursor: 'pointer',
            }}
          >
            <ContainerOl>
              <Row justify={'center'} style={{ width: '100%' }}>
                <Col xs={24} style={{ marginBottom: 40 }}>
                  <Layout
                    style={{
                      padding: '20px 10px',
                      cursor: 'pointer',
                      color: COLORS.PRIMARY.BLUE,
                      fontSize: '20px',
                      fontWeight: 'bold',
                    }}
                  >
                    Միջոցառման պայմաններ
                  </Layout>
                </Col>
                <Col xs={24}>
                  <Text style={{ fontWeight: 600 }}>
                    Այս պայմանները սահմանում են այն կանոններն ու պահանջները, որոնք վերաբերում են «Անհուսալի վարկեր
                    ունեցող անձանց տնտեսական ակտիվության խթանման միջոցառմանը»: Խնդրում ենք ուշադիր կարդալ և հաստատել Ձեր
                    համաձայնությունը այս պայմաններին՝ միջոցառմանը առցանց դիմելու համար:
                  </Text>
                  <ol>
                    <li style={{ margin: '15px 0' }}>
                      <b style={{ color: '#3B3F5F' }}>Ընդհանուր դրույթներ</b>
                      <ol>
                        <li style={{ marginBottom: 5 }}>
                          Միջոցառման նպատակը՝ աջակցել աշխատաշուկայից (ֆորմալ զբաղվածությունից) դուրս մնացածներին կամ
                          մասնակի
                          ֆորմալ զբաղվածություն ունեցողներին՝ վերականգնելու իրենց տնտեսական ակտիվությունն աշխատաշուկա
                          վերադառնալու միջոցով:
                        </li>
                        <li style={{ marginBottom: 5 }}>
                          Միջոցառումը նախատեսված է միայն Հայաստանի Հանրապետության քաղաքացիների կամ ՀՀ ժամանակավոր
                          պաշտպանության կարգավիճակ ունեցող անձանց համար:
                        </li>
                      </ol>
                    </li>

                    <li style={{ margin: '15px 0' }}>
                      <b style={{ color: '#3B3F5F' }}>Մասնակցության իրավասու անձինք</b>
                      <ol>
                        <li style={{ marginBottom: 5 }}>
                          Դիմելու իրավունք ունեն այն անձինք, ովքեր ունեն առնվազն 3 տարվա վաղեմության անհուսալի վարկ(եր),
                          որի(որոնց) մայր գումարը չի գերազանցում 1 մլն ՀՀ դրամը:
                        </li>
                        <li style={{ marginBottom: 5 }}>
                          Դիմելու պահին մասնակիցը պետք է լինի պաշտոնապես գրանցված աշխատող կամ զբաղված քաղաքացիաիրավական
                          պայմանագրով՝ համաձայն ՀՀ պետական եկամուտների կոմիտեի տվյալների: Եթե դիմելու օրվա դրությամբ
                          դուք
                          չեք հանդիսանում աշխատող, ապա կարող եք գրանցվել ՀՀ ԱՍՀՆ զբաղվածության
                          <a href="https://e-work.am" rel="noopener noreferrer"> www.e-work.am</a> հարթակում և գտնել
                          Ձեզ հարմար աշխատանք, կամ գտնել աշխատանք այլ տարբերակով և կրկին ներկայացնել դիմում։
                        </li>
                      </ol>
                    </li>

                    <li style={{ margin: '15px 0' }}>
                      <b style={{ color: '#3B3F5F' }}>Դիմելու կարգը</b>
                      <ol>
                        <li style={{ marginBottom: 5 }}>
                          Դիմումները ներկայացվում են առցանց՝ <a href="https://e-social.am" rel="noopener noreferrer">
                          www.e-social.am</a> կայքի միջոցով կամ անձամբ՝ ՄՍ ծառայության ստորաբաժանումներում:
                        </li>
                        <li style={{ marginBottom: 5 }}>
                          Դիմումն ընդունվում է «Ես Եմ» նույնականացման հարթակի միջոցով հաստատումից հետո:
                        </li>
                        <li style={{ marginBottom: 5 }}>
                          Առցանց դիմումի դեպքում մասնակցի վերաբերյալ տվյալները ստուգվում են պետական տվյալների
                          շտեմարանների
                          փոխգործելիությամբ՝ դիմողի համաձայնությունը ստանալուց հետո:
                        </li>
                      </ol>
                    </li>

                    <li style={{ margin: '15px 0' }}>
                      <b style={{ color: '#3B3F5F' }}>Աջակցության պայմաններ</b>
                      <ol>
                        <li style={{ marginBottom: 5 }}>
                          Միջոցառման շրջանակում աջակցությունն ընդգրկում է աշխատավարձից և դրան հավասարեցված վճարումներից
                          (քաղաքացիաիրավական պայմանագրի շրջանակներում ստացված եկամտից) հաշվարկված եկամտային հարկի
                          գումարի 100%-ը, որն ուղղվում է անհուսալի վարկային պարտավորության մարմանը:
                        </li>
                        <li style={{ marginBottom: 5 }}>
                          Աջակցությունն տրամադրվում է առավելագույնը 24 ամսով կամ մինչև անհուսալի վարկի ամբողջական
                          մարումը:
                        </li>
                        <li style={{ marginBottom: 5 }}>
                          Հարկադիր կատարումն ապահովող ծառայության կողմից շահառուի նկատմամբ բանկի կամ վարկային
                          կազմակերպության
                          կողմից ներկայացված հարկադիր կատարման ենթակա ակտերի կատարման ընթացքում շահառուի աշխատավարձից
                          (քաղաքացիաիրավական պայմանագրի շրջանակներում ստացված եկամտից) բռնագանձվող գումարի չափը
                          սահմանվում
                          է 20% (եթե օրենքով կամ կատարման ենթակա ակտով բռնագանձման առավել նվազ չափ սահմանված չէ)՝ 24
                          ամիս
                          ժամկետով՝ սկսած շահառու ճանաչվելու վերաբերյալ տեղեկատվությունը ստանալուն հաջորդող ամսվա 1-ից։
                        </li>
                      </ol>
                    </li>

                    <li style={{ margin: '15px 0' }}>
                      <b style={{ color: '#3B3F5F' }}>Պարտավորություններ և սահմանափակումներ</b>
                      <ol>
                        <li style={{ marginBottom: 5 }}>
                          Մասնակիցը չի կարող կրկին դիմել Միջոցառմանը, եթե արդեն մեկ անգամ ճանաչվել է շահառու:
                        </li>
                        <li style={{ marginBottom: 5 }}>
                          Եթե մասնակիցը 24 ամսվա ընթացքում 3 ամիս անընդմեջ դադարում է լինել աշխատող, աջակցությունն
                          դադարում է՝ առանց շահառուի կարգավիճակի վերականգման հնարավորության:
                        </li>
                        <li style={{ marginBottom: 5 }}>
                          Միջոցառման շրջանակում տվյալների անճշտության, կեղծիքի կամ խարդախության դեպքում աջակցությունը
                          դադարեցվում է, և ավել վճարված գումարները ենթակա են հետգանձման:
                        </li>
                      </ol>
                    </li>

                    <li style={{ margin: '15px 0' }}>
                      <b style={{ color: '#3B3F5F' }}>Գաղտնիություն և տվյալների պաշտպանություն</b>
                      <ol>
                        <li style={{ marginBottom: 5 }}>
                          Մասնակցի անձնական տվյալները պաշտպանվում են ՀՀ օրենսդրությամբ սահմանված կարգով:
                        </li>
                        <li style={{ marginBottom: 5 }}>
                          Դիմում ներկայացնելով՝ մասնակիցը համաձայնում է իր տվյալների մշակմանն ու փոխանցմանը պետական և
                          ֆինանսական կազմակերպություններին:
                        </li>
                      </ol>
                    </li>

                    <li style={{ margin: '15px 0' }}>
                      <b style={{ color: '#3B3F5F' }}>Վեճերի կարգավորում</b>
                      <ol>
                        <li style={{ marginBottom: 5 }}>Բոլոր վեճերը կարգավորվում են ՀՀ օրենսդրության համաձայն:</li>
                        <li style={{ marginBottom: 5 }}>
                          Վեճերի դեպքում մասնակիցը կարող է դիմել ՄՍ ծառայությանը կամ այլ իրավասու մարմիններին:
                        </li>
                      </ol>
                    </li>

                    <li style={{ margin: '15px 0' }}>
                      <b style={{ color: '#3B3F5F' }}>Կապ և աջակցություն</b>
                      <ol>
                        <li style={{ marginBottom: 5 }}>
                          Միջոցառման վերաբերյալ հարցերի կամ աջակցության համար կարող եք դիմել ՄՍ ծառայության տարածքային
                          ստորաբաժանումներ կամ զանգահարեք աջակցման կենտրոն:
                        </li>
                        <li style={{ marginBottom: 5 }}>
                          Առցանց դիմումների հետ կապված տեխնիկական խնդիրների դեպքում կարող եք գրել
                          <a href="mailto:info@socservice.am"> info@socservice.am</a> էլ․հասցեին։
                        </li>
                      </ol>
                    </li>
                  </ol>

                </Col>
              </Row>
            </ContainerOl>
          </ContentWrapper>
        </Col>
      </Row>
    </Layout>
);
};
