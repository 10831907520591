import { Col, Layout, Row } from 'antd';
import { VerticalSpace } from '../../components/space/vertical-space';
import { Button } from '../../components/button';
import { useGetApplicationTypes } from '../../api/benefit/use-get-application-types';
import { URL_GET_GET_SERVICE_TYPES } from '../../api/benefit/constants';
import { ContentWrapper } from '../../components/layouts/content-wrapper';
import { Title } from '../../components/typography';
// import { ServicesHeader } from './services-header';
import { ServicesSider } from '../../components/layouts/service-sider';
import { COLORS } from '../../helpers/constants';
import { ServicesDepartment } from './services-department';
import { useAuth } from '../../context/auth-context';
import { Link } from 'react-router-dom';
import { Card } from '../../components/card';
import { ManagePage } from '../../components/layouts/manage-page';
import { useState } from 'react';

export const ArcaxSupport = () => {
  const { user } = useAuth();
  const systemId = 11;
  const [isLegal, setIsLegal] = useState(false);
  const { data } = useGetApplicationTypes(URL_GET_GET_SERVICE_TYPES, { systemId: systemId });
  return (
    <>
      {!user && (
        <>
          <ManagePage />
          {/*<ServicesHeader />*/}
        </>
      )}
      <Layout>
        {!user && <ServicesSider />}
        <Layout>
          <ServicesDepartment setIsLegal={setIsLegal} />
          <ContentWrapper style={{ minHeight: 707, background: COLORS.SECONDARY.ALICE_BLUE }}>
            <Row justify={'space-between'} gutter={[16, 16]}>
              {data &&
                data?.map(
                  (item, key) =>
                    item.id > 0 && (
                      <Col
                        xs={24}
                        sm={12}
                        md={12}
                        lg={12}
                        xl={10}
                        xxl={{
                          span: 7,
                          offset: 1,
                        }}
                        style={{ margin: 0 }}
                        key={key}
                      >
                        <Card
                          bodyStyle={{ minHeight: 320 }}
                          actions={[
                            <Button
                              size="large"
                              key="button"
                              style={{
                                float: 'left',
                                background: user ? COLORS.SECONDARY.AQUA_BLUE : COLORS.SECONDARY.OIL,
                                margin: '0 20px',
                              }}
                              type="primary"
                            >
                              {isLegal ? (
                                <Link
                                  style={{ color: COLORS.PRIMARY.WHITE }}
                                  to={item.url}
                                  state={{ from: 'homepage' }}
                                >
                                  {item.id === 4 ? 'Ստուգել տվյալները' : 'Տեսնել ավելին'}
                                </Link>
                              ) : (
                                <Link
                                  style={{ color: COLORS.PRIMARY.WHITE }}
                                  to={item.url}
                                  state={{ from: 'homepage' }}
                                >
                                  {item.id === 4 ? 'Ստուգել տվյալները' : 'Տեսնել ավելին'}
                                </Link>
                              )}
                            </Button>,
                          ]}
                        >
                          <VerticalSpace>
                            <Title style={{ fontSize: 17 }}>{item.decription}</Title>
                          </VerticalSpace>
                        </Card>
                      </Col>
                    )
                )}
            </Row>
          </ContentWrapper>
        </Layout>
      </Layout>
    </>
  );
};
