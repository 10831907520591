import { useEffect, useState } from 'react';
import { Button, Col, Layout, Row } from 'antd';
import { ContentWrapper } from '../../components/layouts/content-wrapper';
import { Title } from '../../components/typography';
import { ServicesSider } from '../../components/layouts/service-sider';
import { COLORS, ESOCIAL_DOMAIN, PATHS } from '../../helpers/constants';
import { ServicesDepartment } from './services-department';
import { useAuth } from '../../context/auth-context';
import { Card } from '../../components/card';
import { ManagePage } from '../../components/layouts/manage-page';
import styled from 'styled-components';
import {
  birthChild,
  fourteenMenu,
  isLegalTypes,
  newApartment,
  pension,
  subMenuTypes,
  workEmployment,
} from './constant';
import { useLocation, useNavigate } from 'react-router-dom';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { useGetNidUrl } from 'api/auth/use-get-nid-url';
import { URL_LOGIN_NID_USER } from 'api/auth/constants';

const CustomCard = styled(Card)`
  ${({ isGray }) =>
    isGray &&
    `
    .ant-card-actions {
      background: #e1e1e1 !important;
    }
  `}
`;

const CustomCardTitle = styled(Title)`
  @media (max-width: 570px) {
    text-align: center;
  }

  @media (max-width: 500px) {
    font-size: 16px !important;
  }
`;

export const Subservices = () => {
  const { user } = useAuth();
  const navigate = useNavigate();
  const [isLegal, setIsLegal] = useState(false);
  const currentDomain = window.location.hostname;
  const isCurrentDomainESocial = ESOCIAL_DOMAIN.includes(currentDomain);
  const url = useGetNidUrl(URL_LOGIN_NID_USER);
  const { state } = useLocation();
  const midLogin = localStorage.getItem('midLogin');

  const handleClick = (item) => {
    // Check and remove specific values based on the clicked item
    switch (item.id) {
      case 9: // SupportNeed
        if (localStorage.getItem('rapidNeed')) {
          localStorage.removeItem('rapidNeed');
        }
        if (localStorage.getItem('creditApplication')) {
          localStorage.removeItem('creditApplication');
        }
        break;
      case 2: // RapidNeed
        if (localStorage.getItem('supportNeed')) {
          localStorage.removeItem('supportNeed');
        }
        if (localStorage.getItem('creditApplication')) {
          localStorage.removeItem('creditApplication');
        }
        break;
      case 4: // CreditApplication
        if (localStorage.getItem('rapidNeed')) {
          localStorage.removeItem('rapidNeed');
        }
        if (localStorage.getItem('supportNeed')) {
          localStorage.removeItem('supportNeed');
        }
        break;
      default:
        break; // No action needed for other cases
    }

    ////// this condition shouldn't be in production!!!! ////////////
    if (item.id === 9) return;

    if (state?.type === 4 && item.id === 9 && user) {
      navigate(item.link);
    }
    if ((state?.type === 4 && item.id === 4) || isLegal) {
      if (user && midLogin) {
        navigate(item.link);
      } else {
        localStorage.setItem('creditApplication', true);
        window.location.href = url.data;
      }
    } else if (state?.type === 4 && item?.id === 2 && !user) {
      navigate(PATHS.SIGN_IN);
      localStorage.setItem('rapidNeed', true);
    } else {
      if (state?.type === 4 && item.id === 3) {
        navigate(item.link, { state: { fourteen: true } });
      } else {
        ///// this condition should not go to prod /////
        if (state?.type === 4 && (item.id === 2 || item.id === 8 || item.id === 9) && !user) {
          item?.id === 8 && localStorage.setItem('supportNeed', true);
          navigate(PATHS.SIGN_IN);
        } else if (state?.type === 4 && (item.id === 2 || item.id === 8 || item.id === 9) && user) {
          navigate(item.link);
        } else {
          window.open(item.link, '_blank');
        }
      }
    }
  };

  const menuItems =
    isLegal && !user
      ? isLegalTypes
      : state.fourteen
      ? fourteenMenu
      : state?.type === 1
      ? birthChild
      : state?.type === 4
      ? subMenuTypes
      : state?.type === 2
      ? workEmployment
      : state?.type === 3
      ? newApartment
      : state?.type === 5
      ? pension
      : [];

  useEffect(() => {
    window.scrollTo(10, 10);
  }, []);

  return (
    <>
      {!user && (
        <>
          <ManagePage />
        </>
      )}

      <Layout>
        {!user && <ServicesSider />}
        <Layout>
          <ServicesDepartment setIsLegal={setIsLegal} />
          <ContentWrapper style={{ background: COLORS.SECONDARY.ALICE_BLUE }}>
            <Button
              onClick={() => navigate(-1)}
              style={{ background: 'none', margin: '10px 0', border: 'none' }}
              aria-label="Նախորդ էջ"
            >
              <ArrowLeftOutlined style={{ fontSize: 30, color: COLORS.SECONDARY.OIL }} />
            </Button>
            <Row gutter={[16, 16]}>
              {menuItems.map((item) => {
                if (item.id === 12 && isCurrentDomainESocial) {
                  return null;
                }

                return (
                  item.id > 0 && (
                    <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={12} style={{ margin: 0 }} key={item?.id}>
                      <CustomCard
                        bodyStyle={{
                          cursor: 'pointer',
                          boxShadow: '2px 2px 4px 2px #00000040',
                          minHeight: 180,
                        }}
                        role="button"
                        tabIndex={0}
                        aria-label={item.label}
                        onClick={() => handleClick(item)}
                      >
                        <Row align={'middle'} gutter={16}>
                          <Col xs={24} sm={8} md={8} lg={8} style={{ display: 'flex', justifyContent: 'center' }}>
                            <img src={item.icon} alt="icon" />
                          </Col>
                          <Col xs={24} sm={16} md={16} lg={16}>
                            <CustomCardTitle
                              color={state?.type === 4 && item.id === 9 ? '#c6cbd3' : '#3B3F5F'}
                              style={{ fontSize: 20, textTransform: 'uppercase' }}
                            >
                              {item.label}
                            </CustomCardTitle>
                          </Col>
                        </Row>
                      </CustomCard>
                    </Col>
                  )
                );
              })}
            </Row>
          </ContentWrapper>
        </Layout>
      </Layout>
    </>
  );
};
