import { useState } from 'react';
import { Select } from 'antd';

const { Option } = Select;

export const ComboboxSelect = ({ selectOptions = [], disabled = false, onChange, value, ...props }) => {
  const [options, setOptions] = useState(selectOptions);
  const [inputValue, setInputValue] = useState({ label: value?.label || '', value: value?.value || '' });

  const handleChange = (value) => {
    const selectedOption = typeof value === 'object' ? value : { label: value, value };
    setInputValue(selectedOption);
    onChange(selectedOption);
  };

  const handleSearch = (searchText) => {
    setInputValue({ label: searchText, value: searchText });
  };

  const getOptions = () => {
    const normalizedInput = typeof inputValue?.label === 'string' ? inputValue.label.toLowerCase() : '';

    const filteredOptions = options.filter((option) => option.label.toLowerCase().includes(normalizedInput));

    // Include the input as an option if it doesn't match existing labels
    if (normalizedInput && !options.some((option) => option.label.toLowerCase() === normalizedInput)) {
      filteredOptions.unshift({ label: inputValue.label, value: inputValue.label });
    }

    return filteredOptions;
  };

  const handleBlur = () => {
    const normalizedInput = typeof inputValue?.label === 'string' ? inputValue.label : '';

    if (normalizedInput && !options.some((option) => option.label.toLowerCase() === normalizedInput.toLowerCase())) {
      setOptions([{ label: normalizedInput, value: normalizedInput }, ...options]);
    }

    if (normalizedInput) {
      if (normalizedInput) {
        onChange({ label: normalizedInput, value: normalizedInput }); // Ensure parent receives the value
      }
    }
  };

  return (
    <Select
      placeholder="Select or type a value"
      value={inputValue?.value}
      onChange={handleChange}
      onSearch={handleSearch}
      onBlur={handleBlur}
      showSearch
      mode="combobox"
      labelInValue
      filterOption={false}
      disabled={disabled}
      {...props}
    >
      {getOptions().map((option) => (
        <Option key={option.value} value={option.value}>
          {option.label}
        </Option>
      ))}
    </Select>
  );
};
