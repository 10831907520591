import styled from 'styled-components';
import {
  FunnelPlotOutlined,
  EyeOutlined,
  PrinterOutlined,
  CloseOutlined,
  EditOutlined,
  DeleteRowOutlined,
  CopyOutlined,
  FileDoneOutlined,
  SolutionOutlined
} from '@ant-design/icons';
import { COLORS } from '../../../helpers/constants';
import { ActionsPopover } from '../../popover';
import { useState } from 'react';
import { Button } from '../index';

const ActtionButton = styled(FunnelPlotOutlined)`
  display: flex;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  background: #fa396b;
  border-radius: 50%;
  svg {
    color: ${COLORS.PRIMARY.WHITE};
  }
`;

const ActionsContent = styled.div`
  .action {
    position: absolute;
    color: ${COLORS.PRIMARY.WHITE};
    font-size: 18px;
    padding: 5px;
    cursor: pointer;
    transform: rotate(90deg);

    &:hover {
      background: #de3963;
      border-radius: 50%;
    }
  }

  .close {
    right: 40%;
    bottom: 40%;
  }

  .delete {
    left: 5px;
    bottom: 62px;
  }

  .print {
    right: 32%;
    top: 10px;
  }

  .decision {
    right: 79px;
    top: 12px;
  }

  .view {
    right: 5px;
    bottom: 40%;
  }

  .edit {
    right: 15px;
    top: 34px;
  }

  .clone {
    left: 10px;
    top: 41px;
  }
`;

export const Actions = ({ items, onView, onClone, onPrint, onDelete, onEdit, onDecision, onAppeal, onReconfirm }) => {
  const [visible, setVisible] = useState(false);

  if (items?.actions?.includes('reconfirm')) {
    return <Button type="primary" onClick={onReconfirm}>Վերահաստատել</Button>;
  }

  return (
    <ActionsPopover
      open={visible}
      onOpenChange={(open) => {
        !open && setVisible(false);
      }}
      content={
        <ActionsContent>
          {items?.actions.map((item) => {
            switch (item) {
              case 'view':
                return <EyeOutlined key={item} className="action view" onClick={onView} title="Դիտել" />;
              case 'edit':
                return <EditOutlined key={item} className="action edit" onClick={onEdit} title="Խմբագրել" />;
              case 'print':
                return <PrinterOutlined key={item} className="action print" onClick={onPrint} title="Տպել" />;
              case 'clone':
                return <CopyOutlined className="action clone" onClick={onClone} title="Կրկնօրինակել" />;
              case 'delete':
                return <DeleteRowOutlined key={item} className="action delete" onClick={onDelete} title="Ջնջել" />;
              case 'decision':
                return <FileDoneOutlined key={item} className="action decision" onClick={onDecision} title="Որոշում" />;
              case 'appeal':
                return <SolutionOutlined key={item} className="action decision" onClick={onAppeal} title="Բողոքարկում" />;
            }
          })}
          <CloseOutlined key={items} className="action close" onClick={() => setVisible(false)} title="Փակել" />
        </ActionsContent>
      }
    >
      <ActtionButton title="Գործողություններ" onClick={() => setVisible(true)} />
    </ActionsPopover>
  );
};
