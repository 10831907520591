import {useQuery} from '@tanstack/react-query';
import {client} from "../client";


export const useGetUserData =  (url, params, options = { enabled: true }) => {
    const result = useQuery({
        queryKey: [url, params],
        queryFn: () => client.get(url, { params }),
        select: (data) => data.data,
        ...options,
    });
    const { data, isSuccess } = result;

    return {
        days: isSuccess ? data?.data : [],
        ...result,
    };
};
