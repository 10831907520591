import { useState, useCallback } from 'react';
import { Calendar, Row, Col, Typography, Space } from 'antd';
import dayjs from 'dayjs';

import { months, getEventData } from '../utils';
import { CalendarModal } from './calendar-modal';
import { VerticalSpace } from 'components/space/vertical-space';

export const CalendarYear = ({ days = [], year }) => {
  const [monthData, setMonthData] = useState();

  const handleModalCancel = useCallback(() => {
    setMonthData();
  }, []);

  const dateCellRender = (date) => {
    const isEventDay = getEventData(date, days);
    return (
      <div style={isEventDay ? { background: '#3B3F5F', color: '#ffffff', margin: '6px' } : {}}>{date.date()}</div>
    );
  };
  return (
    <VerticalSpace size={52} direction="vertical">
      <Row gutter={16}>
        {Object.entries(months).map(([key, month]) => (
          <Col
            lg={8}
            md={12}
            key={key}
            style={{
              background: '#F0F5FF',
              borderRadius: '5px',
              border: '1px dashed #3B3F5F',
              cursor: 'pointer',
            }}
            onClick={() => setMonthData(key)}
          >
            <Calendar
              fullCellRender={dateCellRender}
              fullscreen={false}
              headerRender={() => {
                return (
                  <div style={{ marginRight: 5, padding: '1px 12px' }}>
                    <Space direction="horizontal" style={{ width: '100%', justifyContent: 'end' }}>
                      <Typography.Text>{month}</Typography.Text>
                    </Space>
                  </div>
                );
              }}
              cellRender={false}
              validRange={[
                dayjs().set('year', year).month(key).startOf('month'),
                dayjs().set('year', year).month(key).endOf('month'),
              ]}
              value={dayjs().set('year', year).month(key)}
            />
          </Col>
        ))}
      </Row>
      {monthData && <CalendarModal handleModalCancel={handleModalCancel} year={year} month={monthData} />}
    </VerticalSpace>
  );
};
