import { useState, useEffect } from 'react';
import { MainSider } from '../menu/main-sider';
import { ServicesNavbar } from '../menu/services-navbar';
import { COLORS } from '../../helpers/constants';
import { MenuOutlined } from '@ant-design/icons';
import { useAuth } from 'context/auth-context';

export const ServicesSider = () => {
  const { user } = useAuth();
  const [collapsed, setCollapsed] = useState(true);
  const [siderWidth, setSiderWidth] = useState(window.innerWidth <= 768 ? 340 : 469);

  useEffect(() => {
    const handleResize = () => {
      setSiderWidth(window.innerWidth <= 768 ? 360 : 469);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <MainSider
      user={user}
      style={{
        backgroundColor: COLORS.SECONDARY.OIL,
      }}
      width={siderWidth}
      collapsedWidth="0"
      collapsible
      collapsed={collapsed}
      onCollapse={setCollapsed}
      trigger={<MenuOutlined style={{ fontSize: '20px', color: '#fff' }} />}
    >
      <ServicesNavbar />
    </MainSider>
  );
};
