import { Layout } from 'antd';
import { Outlet } from 'react-router-dom';
import { useMemo } from 'react';
import { SubHeader } from '../sub-header';
import styled from 'styled-components';
import { ManagePage } from '../manage-page';
const { Content } = Layout;


const Wrapper = styled(Content)` 

`;
export const ApplicationLayout = ({ isEdit = false, hideHeader = false, text }) => {
  const context = useMemo(() => ({ isEdit }), [isEdit]);
  return (
    <Layout style={{ background: 'transparent' }}>
      <Wrapper>
        {!hideHeader && <SubHeader text={text} />}
        <ManagePage />
        <Outlet context={context} />
      </Wrapper>
    </Layout>
  );
};
