import styled from 'styled-components';
import { Title } from '../typography';
import {screenSize} from "../../helpers/constants";

const Fieldset = styled.fieldset`
  border: 2px solid #3B3F5F;
  border-radius: 10px;
  padding: 29px 39px 19px;

  legend {
    color: #3B3F5F;
    width: fit-content;
    border-bottom: none;
    padding: 0 12px;
  }
  
  @media only screen and (max-width: ${screenSize.md}) {
    padding: 10px;
  }
`;

export const FormSectionCard = ({ title, children, style = {} }) => {
  return (
    <Fieldset style={style}>
      <Title level={1} as="legend">
        {title}
      </Title>
      {children}
    </Fieldset>
  );
};
