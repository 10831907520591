import { Form, Radio, Space } from 'antd';
import { URL_RESIDENCE_REASONS } from '../../../../api/benefit/constants';
import { useGetFfromBenefit } from '../../../../api/benefit/use-get-dictionary';
import { COLORS, DOCUMENT_TYPES } from 'helpers/constants';
import { FormSectionFullCard } from '../../../card/form-section-full-card';
import { VerticalSpace } from '../../../space/vertical-space';
import { Text, Title } from '../../../typography';
import { FormItem } from '../../form-item';
import { useApplication } from 'context/applicaton-context';
import { files, formItemStepTwoParent } from 'pages/application/constants';
import { DocumentUpload } from 'components/upload/upload-documets';

export const BuildingInfo = ({ formItemAddressType = {} }) => {
  const { data } = useGetFfromBenefit(URL_RESIDENCE_REASONS);
  const { submittedData, cityzenData } = useApplication();
  const form = Form.useFormInstance();
  const buildInfoSelected = Form.useWatch(['buildInfo', 1]);

  const handleOnChange = (e, id) => {
    if (id === 1) {
      if (e.target.value === 0) {
        form.resetFields([
          [formItemStepTwoParent, files, 'residenceFilesChecksum'],
          formItemAddressType.actualResidenceType.name,
        ]);
      } else if (e.target.value === 1) {
        form.resetFields([['buildInfo', 2]]);
        form.setFieldValue(formItemAddressType.actualResidenceType.name, 1);
      }
    } else if (id === 2) {
      if (e.target.value === 1) {
        form.setFieldValue(formItemAddressType.actualResidenceType.name, 2);
      } else {
        form.resetFields([formItemAddressType.actualResidenceType.name]);
      }
    }
  };

  return (
    <FormSectionFullCard>
      <VerticalSpace>
        {data?.map((item) => (
          <VerticalSpace key={item.id}>
            <FormItem label={false} noStyle name={formItemAddressType.actualResidenceType.name}></FormItem>
            <FormItem
              label={
                <Title level={3} as="p" color={COLORS.PRIMARY.BLUE}>
                  {item.name}
                </Title>
              }
              rules={[
                { required: item.id === 1 || (item.id === 2 && buildInfoSelected === 0), message: 'Ընտրեք արժեքը' },
              ]}
              name={['buildInfo', item.id]}
            >
              <Radio.Group
                onChange={(e) => handleOnChange(e, item.id)}
                disabled={item.id === 2 && (buildInfoSelected === undefined || buildInfoSelected === 1)}
              >
                <Space>
                  <Radio value={1}>
                    <Text color={COLORS.PRIMARY.BLUE_DARK}>Այո</Text>
                  </Radio>
                  <Radio value={0}>
                    <Text color={COLORS.PRIMARY.BLUE_DARK}>Ոչ</Text>
                  </Radio>
                </Space>
              </Radio.Group>
            </FormItem>
            {item.id === 1 && buildInfoSelected === 1 && (
              <DocumentUpload
                tempApplicationId={submittedData.tempApplicationId}
                attachedDocumentTypeId={DOCUMENT_TYPES.FROM_HOME_PLACE}
                attachedDocumentName={'Տեղեկանք բնակության վայրի մասին'}
                infoText="Խնդրում ենք կցել տեղական ինքնակառավարման մարմնի կողմից տրամադրված տեղեկանք"
                userData={cityzenData?.citizenInfo}
                propName={'buildingFiles'}
                checksumName={[formItemStepTwoParent, files, 'residenceFilesChecksum']}
              />
            )}
          </VerticalSpace>
        ))}
      </VerticalSpace>
    </FormSectionFullCard>
  );
};
