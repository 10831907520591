import { FormSectionCard } from '../../../card/form-section-card';
import { Col, Radio, Row, Space } from 'antd';
import { FormItem } from '../../form-item';
import { COLORS, DOCUMENT_TYPES } from '../../../../helpers/constants';
import { Text } from '../../../typography';
import { files, formItemStepTwoParent } from '../../../../pages/application/constants';
import { Form } from '../../form';
import { useGetFfromBenefit } from '../../../../api/benefit/use-get-dictionary';
import { URL_ANIMALS } from '../../../../api/benefit/constants';
import { AnimalForm } from './animal-form';

import { useApplication } from 'context/applicaton-context';
import { DocumentUpload } from 'components/upload/upload-documets';

export const FamilyHasLivestock = () => {
  const { submittedData, cityzenData } = useApplication();

  const hasAnimals = Form.useWatch([formItemStepTwoParent, 'hasAnimals']);
  const { data } = useGetFfromBenefit(URL_ANIMALS, {}, { enabled: !!hasAnimals });

  return (
    <FormSectionCard title="Ես/Ընտանիքս ունի ընտանի կենդանիներ" style={{ display: 'grid' }}>
      <FormItem name={[formItemStepTwoParent, 'hasAnimals']} rules={[{ required: true }]}>
        <Radio.Group>
          <Space>
            <Radio value={true}>
              <Text color={COLORS.PRIMARY.BLUE}>Այո</Text>
            </Radio>

            <Radio value={false}>
              <Text color={COLORS.PRIMARY.BLUE}>Ոչ</Text>
            </Radio>
          </Space>
        </Radio.Group>
      </FormItem>
      {hasAnimals === true && (
        <>
          <Row gutter={[24, 24]} justify="flex-start">
            {data.map((item, key) => (
              <Col xs={24} sm={24} md={12} key={key}>
                <AnimalForm animal={item} />
              </Col>
            ))}
          </Row>

          <DocumentUpload
            tempApplicationId={submittedData.tempApplicationId}
            attachedDocumentTypeId={DOCUMENT_TYPES.ANIMAL}
            attachedDocumentName={'Տեղեկանք անասնաբույժից'}
            infoText="Խնդրում ենք կցել անասնաբույժի կողմից վերջին 12 ամների ընթացքում տրամադրված տեղեկանքը:"
            userData={cityzenData?.citizenInfo}
            propName={'livestockFiles'}
            checksumName={[formItemStepTwoParent, files, 'animalFilesChecksum']}
          />
        </>
      )}
    </FormSectionCard>
  );
};
