import { Col, Row } from 'antd';
import { useNavigate } from 'react-router-dom';
import { UserOutlined, LogoutOutlined, HomeOutlined, SettingOutlined } from '@ant-design/icons';
import { ReactComponent as Callback } from '../../icons/callback.svg';

import { useAuth } from '../../context/auth-context';
import { Button } from '../button';
import { COLORS, PATHS } from '../../helpers/constants';
import { NotificationManage } from '../../pages/notifications/notification-manage';
import { Text } from 'components/typography';

export const ManagePage = () => {
  const navigate = useNavigate();
  const { user, logout } = useAuth();

  const logOut = () => {
    localStorage.removeItem('creditApplication');
    localStorage.removeItem('rapidNeed');
    localStorage.removeItem('supportNeed');
    localStorage.removeItem('midLogin');
    logout();
  };

  return (
    <>
      {user ? (
        <Row
          justify="end"
          style={{ background: user ? 'none' : COLORS.PRIMARY.WHITE, height: '50px', width: '100%' }}
          align="middle"
        >
          <Col>
            <NotificationManage />
            <Button type="text" onClick={() => navigate(`${PATHS.HOME}`)} style={{ padding: '0px 10px' }}>
              <HomeOutlined style={{ cursor: 'pointer', fontSize: '23px', color: COLORS.SECONDARY.OIL }} />
            </Button>
            <Button type="text" onClick={() => navigate(`${PATHS.ACCOUNT}`)} style={{ padding: '0px 10px' }}>
              <UserOutlined style={{ cursor: 'pointer', fontSize: '23px', color: COLORS.SECONDARY.OIL }} />
            </Button>
            <Button type="text" onClick={() => navigate(`${PATHS.SETTINGS}`)} style={{ padding: '0px 10px' }}>
              <SettingOutlined style={{ cursor: 'pointer', fontSize: '23px', color: COLORS.SECONDARY.OIL }} />
            </Button>
            <Button type="text" onClick={logOut} style={{ padding: '0px 10px' }}>
              <LogoutOutlined style={{ cursor: 'pointer', fontSize: '23px', color: COLORS.SECONDARY.OIL }} />
            </Button>
            {/***********Checkin with management******************/}
            {/*<Row justify={"end"}>*/}
            {/*    <Text style={{padding: 20}}>{user?.firstName} {user?.lastName}</Text>*/}
            {/*</Row>*/}
          </Col>
        </Row>
      ) : (
        <>
          <Row style={{ background: user ? 'none' : COLORS.PRIMARY.WHITE, height: 'auto', width: '100%' }}>
            <Col span={24} style={{ padding: 5 }}>
              <Text style={{ padding: 10, fontWeight: 600 }}>
                <span style={{ background: `${COLORS.SECONDARY.OIL}`, color: COLORS.PRIMARY.WHITE, padding: 3 }}>
                  ԲԵՏԱ
                </span>{' '}
                Հարթակը մշակման փուլում է․ ձեր կարծիքը կօգնի մեզ բարելավել այն:
              </Text>
            </Col>
          </Row>
          <Row
            justify={'end'}
            style={{ background: user ? 'none' : COLORS.PRIMARY.WHITE, height: '50px', width: '100%' }}
            align="middle"
          >
            <Col>
              <Button
                type="primary"
                onClick={() => navigate(user ? `${PATHS.HOME}` : `${PATHS.SIGN_IN}`, { state: { param: 1 } })}
                style={{ padding: '0px 10px', color: user ? 'none' : COLORS.SECONDARY.OIL, background: 'none' }}
                aria-label="Մուտքի էջ"
              >
                <UserOutlined style={{ cursor: 'pointer', fontSize: '23px' }} />
              </Button>
              {/* <Button
              type="primary"
              style={{ padding: '0px 10px', color: user ? 'none' : COLORS.SECONDARY.OIL, background: 'none' }}
            >
              <FlagFilled style={{ cursor: 'pointer', fontSize: '23px' }} />
            </Button> */}
              <Button
                type="primary"
                style={{ padding: '0px 10px', color: user ? 'none' : COLORS.SECONDARY.OIL, background: 'none' }}
                aria-label="Կապ մեզ հետ"
                onClick={() => window.open(PATHS.HOT_LINE, '_blank')}
              >
                <Callback style={{ cursor: 'pointer' }} />
              </Button>
            </Col>
          </Row>
        </>
      )}
    </>
  );
};
