import { Tabs } from 'antd';
import { PersonalData } from './components/personal-data';
import { Companies } from './components/companies';
import { Authorities } from './components/authorities';
import { useState } from 'react';
import { Text } from 'components/typography';
import { COLORS } from 'helpers/constants';

export const Account = () => {
  const [activeTab, setActiveTab] = useState(1);

  const onChange = (val) => {
    setActiveTab(val);
  };
  const items = [
    {
      key: 1,
      label: <Text style={{ color: COLORS.SECONDARY.OIL, fontSize: '18px' }}>Անձնական տվյալներ</Text>,
      children: <PersonalData />,
    },
    {
      key: 2,
      label: <Text style={{ color: COLORS.SECONDARY.OIL, fontSize: '18px' }}>Կազմակերպություններ</Text>,
      children: <Companies />,
    },
    {
      key: 3,
      label: <Text style={{ color: COLORS.SECONDARY.OIL, fontSize: '18px' }}>Լիազորություններ</Text>,
      children: <Authorities />,
    },
  ];

  return (
    <Tabs
      defaultActiveKey={1}
      size="large"
      activeKey={activeTab}
      style={{ marginTop: '30px' }}
      items={items}
      onChange={onChange}
      tabBarStyle={{ color: 'red' }}
    />
  );
};
