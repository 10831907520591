import { Row, Col, Typography } from 'antd';
import dayjs from 'dayjs';
// import { CheckCircleOutlined } from '@ant-design/icons';

export const NonReliableCitizenInfo = ({ data }) => {
  return (
    <div>
      <>
        <Row justify="center" gutter={16}>
          <Col span={24}>
            <Typography.Title level={5} style={{ textAlign: 'center', color: '#3B3F5F' }}>
              Դիմումատուի տվյալներ
            </Typography.Title>
          </Col>
        </Row>
        <Row justify="space-between">
          <Col>ՀԾՀ համար/ՀԾՀ չունենալու մասին տեղեկանքի համար</Col>
          <Col>{data?.ssn}</Col>
        </Row>
        <Row justify="space-between">
          <Col>Անուն, ազգանուն, հայրանուն</Col>
          <Col>
            {data?.firstName} {data?.lastName} {data?.patronymicName}
          </Col>
        </Row>
        <Row justify="space-between">
          <Col>Ծննդյան ամսաթիվ</Col>
          <Col>{data?.birthDate && dayjs(data?.citizenInfo?.birthdate).format('DD.MM.YYYY')} </Col>
        </Row>
        <Row justify="space-between">
          <Col>ԱՀՓ համար</Col>
          <Col>{data?.passport} </Col>
        </Row>
        <Row justify="space-between">
          <Col>Սեռ</Col>
          <Col>{data?.sex}</Col>
        </Row>
        <Row justify="space-between">
          <Col>Հեռախոս</Col>
          <Col>{data?.phone}</Col>
        </Row>
        <Row justify="space-between">
          <Col>Էլեկտրոնային փոստ</Col>
          <Col>{data?.email}</Col>
        </Row>
        {/* <Row justify="space-between" >
                    <Col>Բաժանորդային համար՝</Col>
                </Row>
                <Row >
                    <Col span={23} push={1}>
                        <Row justify="space-between" >
                            <Col>Գազպրոմ Արմենիա</Col>
                            <Col>{data?.gasAccount}</Col>
                        </Row>
                        <Row justify="space-between" >
                            <Col>ՀԵՑ</Col>
                            <Col>{data?.electricityAccount}</Col>
                        </Row>
                        <Row justify="space-between" >
                            <Col>Վեոլիա ջուր</Col>
                            <Col>{data?.waterAccount}</Col>
                        </Row>
                    </Col>
                </Row>
                <Row justify="space-between">
                    <Col>Սովորում է/եմ առկա ուսուցման համակարգում</Col>
                    <Col>{data?.isStudying === true ? 'Այո' : 'Ոչ'}</Col>
                </Row>
                <Row justify="space-between" >
                    <Col>Հանդիսանում է/եմ զինծառայող</Col>
                    <Col>{data?.isMilitaryService === true ? 'Այո' : 'Ոչ'}</Col>
                </Row>
                <Row justify="space-between">
                    <Col>Անասնատեսակի առկայություն</Col>
                    <Col>{data?.animals?.length ? `${data?.animals?.length} հատ` : ''}</Col>
                </Row>
                <Row justify="space-between" >
                    <Col>Վճարման եղանակի ընտրություն</Col>
                </Row>
                <Row >
                    <Col span={23} push={1}>
                        <Row justify="space-between" >
                            <Col>Վճարման տեսակ</Col>
                            <Col>{data?.paymentMethod}</Col>
                        </Row>
                        <Row justify="space-between" >
                            <Col>Նախընտրած բանկ</Col>
                            <Col>{data?.bank}</Col>
                        </Row>
                    </Col>
                </Row> */}
        <Row justify="space-between">
          <Col>Ծանուցումները նախընտրում եմ/ենք ստանալ</Col>
          <Col>{data?.notifyBy}</Col>
        </Row>
      </>
      <Row style={{ paddingTop: '40px', width: '100%' }}>
        {/* <Row justify="space-between">
                    <Col>Ծանուցումը նախընտրում է/եմ ստանալ</Col>
                    <Col>{data?.preferredGetNotified}</Col>
                </Row> */}
        {/* <p>
                    <CheckCircleOutlined style={{ color: '#456FAE' }} />
                    Հավաստիացնում եմ, որ ներկայացրած տվյալները ճիշտ են{' '}
                </p> */}
      </Row>
    </div>
  );
};
