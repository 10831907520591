import { useState } from "react";
import { Spin } from "antd";
import { useParams } from 'react-router-dom';
import styled from 'styled-components';

import { URL_GET_DECISION_BY_ID } from "../../api/application/constants";
import { useDataForApplication } from "../../api/application/use-get-data-for-application";
import { DecisionInfo } from "./components/decision/decision-info";
import { PaymentInfo } from "./components/decision/payment-info";
import { Header } from "./components/header";


const Wrapper = styled.div`
  width: 21cm;
  background: white;
  display: block;
  margin: 0 auto;
  box-shadow: 0 0 0.5cm rgba(0,0,0,0.5); 
  padding: 17px; 
  font: 12pt "Sylfaen"; 
  border: 6px solid #3B3F5F;
  border-radius: 10px;
  box-sizing: border-box; 
`;
const InnerWrapper = styled.div`
    background: #FFFFFF;
    border: 2px solid #3B3F5F;
    border-radius: 10px;
    display: block;
    margin: 0 auto;
    margin-bottom: 0.5cm;
    padding: 30px; 
    box-sizing: border-box;
    .ant-layout-header {
        padding: 0px;
    }
`;


export const DecisionPrint = () => {
    const params = useParams();
    const [result, setResult] = useState([]);

    const { isLoading } = useDataForApplication(URL_GET_DECISION_BY_ID, { applicationId: params.id }, {
        onSuccess: (data) => {
            setResult(data)
        },
        enabled: !!params.id,
    });
    return (
        <Spin spinning={isLoading}>
            <Wrapper className='print'>
                <InnerWrapper className='page'>
                    <div size="large">
                        <Header number={`ԿԱՐԳԱԴՐՈՒԹՅՈՒՆ N ${result.number}`} title='ԱՆԱՊԱՀՈՎՈՒԹՅԱՆ ՆՊԱՍՏ ՆՇԱՆԱԿԵԼՈՒ ՄԱՍԻՆ' />
                        <DecisionInfo data={result} />
                        <PaymentInfo data={result} />
                    </div>
                </InnerWrapper>
            </Wrapper>
        </Spin>
    )
}




