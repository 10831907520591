import { CloseOutlined, EyeOutlined, PaperClipOutlined, PlusSquareOutlined } from '@ant-design/icons';
import { Form, Input, message, Space } from 'antd';
import useFormInstance from 'antd/es/form/hooks/useFormInstance';
import { Datepicker } from 'components/datepicker';
import { ComboboxSelect } from 'components/select/combobox-select';
import dayjs from 'dayjs';
import { COLORS } from 'helpers/constants';
import { createOptionsFromArray } from 'helpers/utils';
import styled from 'styled-components';
import { createFormData } from './utils';
import { URL_UPLOAD_FILE } from 'api/file/constants';
import { client } from 'api/client';
import React, { useState } from 'react';
import { URL_DELETE_FILE, URL_PREVIEW_FILE } from 'api/application/constants';
import { useGetFile } from 'api/application/use-get-file';
import { usePostNoMutate } from 'api/application/use-post-no-mutate';
import { Info } from 'components/typography/info';
import { VerticalSpace } from 'components/space/vertical-space';
import { SmallText } from 'components/typography';
import { useFileContext } from 'context/file-context';
import { requiredMessage } from 'pages/disability-application/constants';
import { v4 as uuidv4 } from 'uuid';

const InputContainer = styled.div`
  position: relative;
`;
const ErrorText = styled.div`
  position: absolute;
  top: 100%;
  left: 0;
  color: ${COLORS.ALERT.RED};
  font-size: 14px;
  line-height: 1.2;
  margin-top: 4px;
`;
const StyledCol = styled.td`
  width: 220px;
  white-space: nowrap;
  :first-child {
    border-radius: 10px 0 0 0;
  }

  :last-child {
    border-radius: 0 10px 0 0;
    width: 50px;
  }
`;
const StyledRow = styled.td`
  min-width: 220px;
  max-width: 220px;
  margin-bottom: 10px;
`;

export const DocumentUpload = React.memo(
  ({
    userData,
    organizations = [],
    attachedDocumentTypeId,
    attachedDocumentName,
    tempApplicationId = '',
    applicationId = '',
    uploadUrl = URL_UPLOAD_FILE,
    propName,
    infoText = '',
    checksumName = [],
    parent = [],
  }) => {
    const [checksumToView, setChecksumToView] = useState();
    const [checksumToRemove, setChecksumToRemove] = useState();
    const { formState, setFormState } = useFileContext();
    const [errors, setErrors] = useState([]);
    const [formList, setFormList] = useState(() => (propName && formState?.[propName] ? formState[propName] : []));

    const form = useFormInstance();

    const validateRow = (row, id) => {
      const rowErrors = errors.find((error) => error.id === id)?.errors || {};
      // Validate document name
      if (!row.documentName) {
        rowErrors.documentName = requiredMessage;
      } else if (row.documentName.length > 150) {
        rowErrors.documentName = 'Անվանումը պետք է պարունակի առավելագույնը 150 նիշ';
      } else {
        delete rowErrors.documentName; // Remove error if validation passes
      }

      // Validate organization
      if (!row.organization) {
        rowErrors.organization = requiredMessage;
      } else if (row.organization?.label.length > 150) {
        rowErrors.documentName = 'Անվանումը պետք է պարունակի առավելագույնը 150 նիշ';
      } else {
        delete rowErrors.organization; // Remove error if validation passes
      }

      // Validate issued date
      if (!row.issuedDate) {
        rowErrors.issuedDate = requiredMessage;
      } else {
        delete rowErrors.issuedDate; // Remove error if validation passes
      }

      if (Object.keys(rowErrors).length > 0) {
        setErrors((prevErrors) => {
          const updatedErrors = prevErrors.filter((error) => error.id !== id); // Remove existing error for the id
          return [...updatedErrors, { id, errors: rowErrors }]; // Add the updated error object
        });
      } else {
        setErrors((prevErrors) => prevErrors.filter((error) => error.id !== id)); // Simply remove the error for the id
      }

      return rowErrors;
    };

    const handleFieldChange = (fieldName, fieldId, value) => {
      const updatedFormList = formList.map((item) =>
        item.id === fieldId
          ? {
              ...item,
              [fieldName]: value instanceof Date ? dayjs(value).format('YYYY-MM-DD') : value,
              attachedDocumentTypeId: attachedDocumentTypeId,
              attachedDocumentName: attachedDocumentName,
            }
          : item
      );

      setFormList(updatedFormList);
      setFormState((prev) => ({
        ...prev,
        [propName]: updatedFormList,
      }));
    };

    const handleUpload = (e, fieldId) => {
      const file = e.target.files[0];
      if (!file) {
        message.error('Please select a file.');
        return;
      }
      // Validate the row fields
      const currentRow = formList.find((row) => row.id === fieldId);
      const rowErrors = validateRow(currentRow, fieldId);

      if (Object.keys(rowErrors).length > 0) {
        setErrors((prevErrors) => {
          const updatedErrors = prevErrors.filter((error) => error.id !== fieldId);
          return [...updatedErrors, { id: fieldId, errors: rowErrors }];
        });

        // Remove the file from the input field to allow re-attach
        e.target.value = null;

        return; // Stop execution if validation fails
      }

      // Validate file type
      const allowedTypes = ['image/jpeg', 'image/png', 'application/pdf'];
      if (!allowedTypes.includes(file.type)) {
        message.error(`Միայն $jpg/png/pdf տեսակի ֆայլերն են թույլատրվում.`);
        return;
      }

      // Validate file size (10MB = 10 * 1024 * 1024 bytes)
      const maxSize = 10 * 1024 * 1024;
      if (file.size > maxSize) {
        message.error(`Ֆայլի չափսը պետք է լինի ${maxSize}MB-ից փոքր`);
        return;
      }
      const currentFileData = formList.find((item) => item.id === fieldId);
      const dataToSave = {
        ssn: userData?.ssn,
        birthDate: dayjs(userData?.birthdate).format('YYYY-MM-DD'),
        attachedDocumentTypeId,
        documentName: currentFileData.documentName,
        documentNumber: currentFileData.documentNumber,
        ...(tempApplicationId
          ? {
              incompleteApplicationId: tempApplicationId,
            }
          : {}),
        applicationId: tempApplicationId ? '' : applicationId || null,
        hash: '',
        organizationId:
          typeof currentFileData?.organization?.value === 'number' ? currentFileData.organization.value : null,
        issuedDate: dayjs(currentFileData.issuedDate).format('YYYY-MM-DD'),
        validUntil: currentFileData.validUntil ? dayjs(currentFileData.validUntil).format('YYYY-MM-DD') : null,
        issuedBy: currentFileData.organization.label,
      };

      const formData = createFormData({ ...dataToSave, file: file });

      client
        .post(uploadUrl, formData)
        .then((res) => {
          const updatedList = formList.map((item) =>
            item.id === fieldId
              ? {
                  ...item,
                  fileChecksum: res.data.fileChecksum,
                }
              : item
          );

          form.setFieldValue(
            [...parent, ...checksumName],
            updatedList.map((item) => item?.fileChecksum)
          );
          setFormState((prev) => ({
            ...prev,
            [propName]: updatedList,
          }));
          setFormList(updatedList);
          message.success('Ֆայլը հաջողությամբ բեռնվել է');
        })
        .catch((err) => {
          // eslint-disable-next-line no-console
          console.error(err);
          message.error('Ֆայլի բեռնումը չի հաջողվել');
        });
    };

    const remove = (idToRemove) => {
      const updatedFormList = formList.filter((row) => row.id !== idToRemove);
      form.setFieldValue(
        [...parent, ...checksumName],
        updatedFormList.map((item) => item?.fileChecksum)
      );
      setFormList(updatedFormList);
      setFormState((prev) => ({
        ...prev,
        [propName]: updatedFormList,
      }));
    };

    const handleDelete = (fieldId, fileChecksum) => {
      try {
        remove(fieldId);
        setChecksumToRemove(fileChecksum);
      } catch {
        message.error('Ֆայլը հեռացնելիս սխալ տեղի ունեցավ');
      }
    };

    useGetFile(
      URL_PREVIEW_FILE,
      { fileChecksum: checksumToView },
      {
        enabled: !!checksumToView,
        onSuccess: () => {
          setChecksumToView();
        },
      }
    );

    usePostNoMutate(
      `${URL_DELETE_FILE}?checksum=${checksumToRemove}`,
      {},
      {
        enabled: !!checksumToRemove,
        onSuccess: () => {
          setChecksumToRemove();
          message.success('Ֆայլը հաջողությամբ ջնջվեց');
        },
      }
    );

    const add = () => {
      //* Disallowing more than one doc to be attached
      if (formList.length >= 1) {
        return;
      }
      const newRow = {
        id: uuidv4(),
        documentName: '',
        documentNumber: '',
        organization: null,
        issuedDate: null,
        validUntil: null,
        attachedDocumentTypeId,
        attachedDocumentName,
        fileChecksum: null,
      };

      setFormList((prev) => [...prev, newRow]);
      setFormState((prev) => ({
        ...prev,
        [propName]: [...(prev[propName] || []), newRow],
      }));
    };

    return (
      <>
        <div style={{ overflow: 'scroll' }}>
          <table style={{ width: '100%', textAlign: 'center', borderSpacing: '0' }} cellPadding={20}>
            <thead style={{ background: COLORS.SECONDARY.OIL, color: '#FFF' }}>
              <tr>
                <StyledCol>Փաստաթղթի անվանում *</StyledCol>
                <StyledCol>Փաստաթղթի տեսակ *</StyledCol>
                <StyledCol>Փաստաթղթի համար</StyledCol>
                <StyledCol>Տրամադրող *</StyledCol>
                <StyledCol>Փաստաթղթի ամսաթիվ *</StyledCol>
                <StyledCol>Վավեր է մինչև</StyledCol>
                <StyledCol>
                  <PlusSquareOutlined
                    style={{
                      fontSize: '20px',
                      color: formList?.length >= 1 && '#ccc',
                      cursor: formList?.length < 1 ? 'pointer' : 'not-allowed',
                    }}
                    onClick={add}
                  />
                </StyledCol>
              </tr>
            </thead>
            <tbody>
              {formList.map((field) => {
                const isFileAttached = field?.fileChecksum;
                return (
                  <tr key={field.id}>
                    <StyledRow>
                      <InputContainer>
                        <Input
                          placeholder="Լրացնել"
                          value={field.documentName}
                          onChange={(e) => handleFieldChange('documentName', field.id, e.target.value)}
                          disabled={isFileAttached}
                        />
                        {errors.find((error) => error.id === field.id)?.errors?.documentName && (
                          <ErrorText>{errors.find((error) => error.id === field.id).errors.documentName}</ErrorText>
                        )}
                      </InputContainer>
                    </StyledRow>
                    <StyledRow>
                      <Input value={attachedDocumentName} disabled />
                    </StyledRow>
                    <StyledRow>
                      <Input
                        placeholder="Լրացնել"
                        value={field.documentNumber}
                        onChange={(e) => handleFieldChange('documentNumber', field.id, e.target.value)}
                        disabled={isFileAttached}
                      />
                    </StyledRow>
                    <StyledRow>
                      <InputContainer>
                        <ComboboxSelect
                          selectOptions={createOptionsFromArray(organizations)}
                          onChange={(val) => handleFieldChange('organization', field.id, val)}
                          style={{ width: '100%' }}
                          disabled={isFileAttached}
                          value={field.organization}
                        />

                        {errors.find((error) => error.id === field.id)?.errors?.organization && (
                          <ErrorText>{errors.find((error) => error.id === field.id).errors.organization}</ErrorText>
                        )}
                      </InputContainer>
                    </StyledRow>
                    <StyledRow>
                      <InputContainer>
                        <Datepicker
                          disabledDate={(current) => current.isAfter(dayjs())}
                          style={{
                            borderRadius: 10,
                            color: '#000',
                          }}
                          disabled={isFileAttached}
                          value={field?.issuedDate ? dayjs(field.issuedDate) : null}
                          onChange={(e) => handleFieldChange('issuedDate', field.id, e)}
                        />

                        {errors.find((error) => error.id === field.id)?.errors?.issuedDate && (
                          <ErrorText>{errors.find((error) => error.id === field.id).errors.issuedDate}</ErrorText>
                        )}
                      </InputContainer>
                    </StyledRow>
                    <StyledRow>
                      <Datepicker
                        disabledDate={(current) => current.isBefore(dayjs())}
                        style={{
                          borderRadius: 10,
                          color: '#000',
                        }}
                        disabled={isFileAttached}
                        onChange={(e) => handleFieldChange('validUntil', field.id, e)}
                        value={field?.validUntil ? dayjs(field.validUntil) : null}
                      />
                    </StyledRow>
                    <td>
                      <Space size="small">
                        <label htmlFor={`filePicker-${field.id}`}>
                          <PaperClipOutlined
                            style={{
                              color: isFileAttached ? '#ccc' : '#000',
                              cursor: !isFileAttached ? 'pointer' : 'not-allowed',
                            }}
                          />
                        </label>
                        <EyeOutlined
                          style={{
                            color: isFileAttached ? '#000' : '#ccc',
                            cursor: isFileAttached ? 'pointer' : 'not-allowed',
                          }}
                          onClick={() => {
                            setChecksumToView(field.fileChecksum);
                          }}
                        />
                        <CloseOutlined onClick={() => handleDelete(field.id, field?.fileChecksum)} />
                      </Space>
                      <Form.Item hidden name={[field.name, 'fileChecksum']} />
                      <input
                        id={`filePicker-${field.id}`}
                        style={{ visibility: 'hidden', width: 0, height: 0, padding: 0 }}
                        type="file"
                        onChange={(e) => handleUpload(e, field.id)}
                      />
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
        <Info
          text={
            <VerticalSpace size="small">
              {infoText && <SmallText color={COLORS.PRIMARY.GRAY_LIGHT}>{infoText}</SmallText>}
              <SmallText color={COLORS.PRIMARY.GRAY_LIGHT}>
                Կցվող փաստաթուղթը պետք է լինի jpg/png/pdf ֆորմատի և չգերազանցի 10MB-ը։
              </SmallText>
            </VerticalSpace>
          }
        />
        <Form.Item
          name={checksumName}
          rules={[
            {
              validator: (_, value) => {
                return value && value.length > 0 ? Promise.resolve() : Promise.reject('Տեղեկանք կցված չէ');
              },
            },
          ]}
        />
      </>
    );
  }
);
