import { Row, Col, Table } from "antd";
import styled from 'styled-components';

const Label = styled.span`
    font-size: 12px;  
    font-weight: 700;
    line-height: 28px;
`;
const Text = styled.span`
  font-size: 12px;   
  align:left
`;
export const PaymentInfo = ({ data }) => {

    const column = [{
        title: () => {
            return <Label>Նպաստի չափը(ՀՀ դրամ)</Label>;
        },
        dataIndex: 'money',
        render: (value) => {
            return <Text>{value}</Text>;
        },
        flex: 1
    },
    {
        title: () => {
            return <Label>Վճարման սկիզբը</Label>;
        },
        dataIndex: 'payStartDate',
        render: (value) => {
            return <Text>{value}</Text>;
        },
        flex: 1,
    }];


    return (
        <>
            <div style={{ paddingTop: '40px', width: '100%' }}>
                <Row justify="space-between" >
                    <Col span={24}>
                        <Label style={{ color: '#3B3F5F' }}>
                            {` ${data?.citizenName}- ի ընտանիքին նշանակվել է անապահովության նպաստ`}
                        </Label>
                    </Col>
                </Row>
                <Table
                    dataSource={[data]}
                    columns={column}
                    pagination={false}
                    bordered locale={{ emptyText: ' ' }}
                />
            </div>
            <div style={{ paddingTop: '40px', width: '100%' }}>
                <Row justify="space-between" >
                    <Col><Label>Դիմումը հաստատող</Label></Col>
                    <Col><Text>{data?.operatorName}</Text></Col>
                </Row>
                <Row justify="space-between" >
                    <Col><Label>Տնայցն իրականացնող</Label></Col>
                    <Col><Text>{data?.socialWorkerName}</Text></Col>
                </Row>
                <Row justify="space-between" >
                    <Col><Label>Միասնական սոցիալական ծառայության տարածքային կենտրոնի տնօրեն</Label></Col>
                    <Col><Text>{data?.commissionHeadName}</Text></Col>
                </Row>
            </div>
        </>
    )
}


