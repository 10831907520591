import { PATHS } from 'helpers/constants';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';

const Title = styled.span`
  font-weight: 700;
  font-size: 20px;
  line-height: 15px;
  text-align: center;
  text-transform: uppercase;
  margin-top: auto;
  margin-bottom: auto;
  color: ${(props) => (props.isRoot ? '#ffffff' : '#000000')};

  @media only screen and (max-width: 768px) {
    font-size: 15px;
    height: 140px;
    line-height: 10px;
  }
`;

export const PageTitle = ({ nonreliable }) => {
  const location = useLocation();
  const isRoot = location.pathname === '/' || location.pathname === PATHS.SUB_SERVICES; // Check if the current path is root

  return (
    <>{!nonreliable && <Title isRoot={isRoot}>ՀՀ ՍՈՑԻԱԼԱԿԱՆ ՊԱՇՏՊԱՆՈՒԹՅԱՆ ԾԱՌԱՅՈՒԹՅՈՒՆՆԵՐԻ ՄԻԱՍՆԱԿԱՆ ՀԱՐԹԱԿ</Title>}</>
  );
};
