import { Col, Layout, Modal, Row } from 'antd';
import backgroundImage from '../../icons/background.svg';
import styled, { css } from 'styled-components';
import { SubHeader } from '../../components/layouts/sub-header';
import { ContentWrapper } from '../../components/layouts/content-wrapper';
import { COLORS } from '../../helpers/constants';
import { Text } from '../../components/typography';
import { Button } from '../../components/button';
import icon from '../user-app/identification/icons/check-circle.svg';
import { useGetNidUrl } from '../../api/auth/use-get-nid-url';
import { URL_LOGIN_NID_USER } from '../../api/auth/constants';

export const BackgroundLayout = styled(Layout)`
  ${(props) =>
  props.inner
    ? css`
          background: transparent;
        `
    : css`
           background-image: url(${backgroundImage});
           background-size: cover;
           background-position: center;
           background-repeat: no-repeat;
           background-color: #ffffff;
        `}
`;

export const SubmitApplicationInfo = () => {
  const url = useGetNidUrl(URL_LOGIN_NID_USER);

  const continueButton = () => {
    window.location.href = url.data
    localStorage.setItem("creditApplication", true)
  }

  const showInfo = () => {
    Modal.info({
      title: 'Ուշադրություն',
      content: (
        <div>
          <p>Հարգելի օգտվող,</p> Դուք վերահասցեագրվում եք «Ես եմ» ազգային նույնականացման հարթակ։ Մուտք գործելուց հետո
          հարթակն ինքնաշխատ եղանակով Ձեզ կվերադարձնի ՀՀ սոցիալական պաշտպանության ոլորտի դիմումների միասնական համակարգ։
        </div>
      ),
      icon: <img src={icon} alt="icon" style={{ marginRight: 5 }} />,
      footer: (
        <div style={{ textAlign: 'center', marginTop: 20 }}>
          <Button
            style={{ width: '150px', padding: '7px 0', height: 'auto', backgroundColor: '#3B3F5F', borderRadius: '28px' }}
            type={'primary'}
            onClick={continueButton}
          >
            Շարունակել
          </Button>
        </div>
      ),
      closable: true,
    });
  }

  return (
    <Layout style={{ minHeight: '100vh' }}>
      <SubHeader />
      <BackgroundLayout>
        <Row justify={'center'}>
          <Col xs={20} sm={20} md={20} lg={12} xl={12}>
            <ContentWrapper
              style={{
                padding: '60px 23px',
                display: 'flex',
                margin: '60px 0',
                alignItems: 'center',
                cursor: 'pointer',
              }}
            >
              <Row justify={'center'} style={{ width: '100%' }}>
                <Col xs={24} style={{ marginBottom: 40 }}>
                  <Layout
                    style={{
                      padding: '20px 10px',
                      cursor: 'pointer',
                      color: COLORS.PRIMARY.BLUE,
                      fontSize: '20px',
                      fontWeight: 'bold'
                    }}
                  >
                    Ինչպես ներկայացնել դիմում
                  </Layout>
                </Col>
                <Col xs={24}>
                  <h2 style={{ margin: '0 0 5px' }}>ՔԱՅԼ 1</h2>
                  <b>Անցեք «ԵսԵմ»-ի մուտքի բջջային ID SIM քարտի կամ նույնականացման քարտի միջոցով նույնականացում։</b>
                  <p style={{ marginBottom: 0 }}>Ստորև ներկայացված են համապատասխան քայլերը յուրաքանչյուր տարբերակի համար.</p>
                  <ul style={{ listStyleType: 'none', paddingLeft: 0, marginTop: 0 }}>
                    <li>
                      <Text strong color="#3B3F5F">1. «ԵսԵմ» նույնականացում բջջային ID SIM քարտի միջոցով (mID)</Text>
                      <ul style={{ listStyleType: "circle", color: "black" }}>
                        <li style={{ marginBottom: 5 }}>Գրանցվեք բջջային օպերատորի մոտ՝ ստանալու համար ID SIM քարտ։</li>
                        <li style={{ marginBottom: 5 }}>Ընտրեք (mID) նույնականացման տարբերակը։</li>
                        <li style={{ marginBottom: 5 }}>«Մուտքագրեք Ձեր հեռախոսահամարը» դաշտում ավելացրեք Ձեր հեռախոսահամարը։</li>
                        <li style={{ marginBottom: 5 }}>Սեղմեք «Մուտք»։</li>
                        <li style={{ marginBottom: 5 }}>Ձեր հեռախոսահամարին կուղարկվի հաղորդագրություն՝ մուտքագրման կոդով։</li>
                        <li style={{ marginBottom: 5 }}>Ստացված կոդը մուտքագրեք համապատասխան դաշտում։</li>
                        <li style={{ marginBottom: 5 }}>Հաստատելուց հետո կհայտնվեք դիմումի ներկայացման էջում։</li>
                      </ul>
                    </li>
                    <li>
                      <Text strong color="#3B3F5F">2. «ԵսԵմ» նույնականացում քարտի (eID) միջոցով</Text>
                      <ul style={{ listStyleType: "circle", color: "black" }}>
                        <li style={{ marginBottom: 5 }}>
                          Պետական և տեղական ինքնակառավարման մարմինների էլեկտրոնային ծառայություններից օգտվելու համար Ձեզ անհրաժեշտ է.
                          <br/>
                          <p style={{ textDecoration: 'underline', fontWeight: 'bold', margin: '7px 0' }}>նույնականացման քարտ (PIN և PUK ծածկագրերով)</p>
                          <p style={{ textDecoration: 'underline', fontWeight: 'bold', margin: '7px 0' }}>նույնականացման քարտ կարդացող սարք</p>
                        </li>
                        <li style={{ marginBottom: 5 }}>Միացրեք նույնականացման քարտը կարդացող սարքին։</li>
                        <li style={{ marginBottom: 5 }}>Ընտրեք (eID) նույնականացման տարբերակը։</li>
                        <li style={{ marginBottom: 5 }}>Տեղադրե՛ք քարտը ընթերցող սարքի մեջ:</li>
                        <li style={{ marginBottom: 5 }}>Սեղմեք «Մուտք»:</li>
                        <li style={{ marginBottom: 5 }}>Հավաքե՛ք մուտքի PIN կոդը:</li>
                        <li style={{ marginBottom: 5 }}>PIN կոդը մուտքագրելուց հետո կհայտնվեք դիմումի ներկայացման էջում։</li>
                      </ul>
                    </li>
                  </ul>
                </Col>
                <Col xs={24}>
                  <h2 style={{ marginBottom: 5 }}>ՔԱՅԼ 2</h2>
                  <b>Ներկայացրեք դիմում</b>
                  <ul>
                    <li style={{ marginBottom: 5 }}>Լրացրեք «Հեռախոսի համար» և «էլեկտրոնային փոստի հասցե» պարտադիր դաշտերը,</li>
                    <li style={{ marginBottom: 5 }}>
                      Հաստատեք  ներկայացված տվյալները ճիշտ են և տալիս եք համաձայնություն  համապատասխան շտեմարաններից  տվյալները ստանալու,
                      մշակելու, օգտագործելու և փոխանցելու վերաբերյալ,
                    </li>
                  </ul>
                </Col>
                <Col xs={24}>
                  <h2 style={{ marginBottom: 5 }}>ՔԱՅԼ 3</h2>
                  <b>Հետևեք դիմումի ընթացքին</b>
                  <ul>
                    <li style={{ marginBottom: 5 }}>
                      Դիմումը ներկայացնելուց անմիջապես հետո տվյալների համապատասխանության դեպքում ստացվում է դիմումի
                      հաստատման մասին հաղորդագրություն, որտեղ նշվում է նաև՝ դիմումի համարը։
                    </li>
                    <li style={{ marginBottom: 5 }}>
                      Տվյալների անհամապատասխանության դեպքում ստացվում է դիմումի մերժման մասին հաղորդագրություն, որտեղ
                      նշվում է մերժման պատճառը,
                      ինչպես նաև՝ դիմումի համարը։
                    </li>
                    <li style={{ marginBottom: 5 }}>Դիմումի հաստատման կամ մերժման մասին հաղորդագրությունները կուղարկվեն նաև Ձեր էլեկտրոնային փոստին։</li>
                  </ul>
                </Col>
                <Col style={{ marginTop: 20 }}>
                  <Button
                    style={{ width: '150px', padding: '7px 0', height: 'auto' }}
                    type="primary"
                    onClick={showInfo}
                  >
                    Դիմել
                  </Button>
                </Col>
              </Row>
            </ContentWrapper>
          </Col>
        </Row>
      </BackgroundLayout>
    </Layout>
  );
};
