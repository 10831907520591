import DifficultSituation from './images/difficult-situation.svg';
import Work from './images/work.svg';
import Pension from './images/pension.svg';
import Baby from './images/baby.svg';
import Apartment from './images/apartment.svg';
import Graduate from './images/graduate.svg';
import UrgentNeed from './images/urgent-need.svg';
import Fourteen from './images/fourteen.svg';
import SupportApplications from './images/support-applications.svg';
import BadCredit from './images/bad-credit.svg';
import PersonalAssistant from './images/personal-assistant.svg';
import Allowance from './images/allowance.svg';
import BirthChild from './images/birthChild.svg';
import ChildBenefit from './images/child-benefit.svg';
import ChildSupport from './images/child-support.svg';
import BankChoice from './images/bank-choice.svg';
import PersonalFunctionality from './images/personal-functionality.svg';
import WorkEmployment from './images/work-employment.svg';
import Npast from './images/npast.svg';
import Disability from './images/disability.svg';
import { PATHS } from 'helpers/constants';

export const menuText = {
  birthChild: {
    label: 'Երեխայի ծնունդ',
    subMenu: {
      allowance: 'Երեխայի ծննդյան միանվագ նպաստ',
      childUpTwoYears: 'Մինչեվ 2 տարեկան երեխայի նպաստ',
      financialSupport: '3-րդ եվ հաջորդ երեխայի դրամական աջակցություն',
      paymentBank: 'Վճարային բանկի ընտրություն',
    },
  },
  workCareer: {
    label: 'Աշխատանք և կարիերա',
    subMenu: {
      programInvolvement: 'Անձի ֆունկցիոնալությունը գնահատող մասնագետների ներգրավման ծրագիր',
      workEmployment: 'Աշխատանք եվ զբաղվածություն',
    },
  },
  newApartment: {
    label: 'Նոր բնակարան',
    subMenu: {
      displacedPeople: 'ԼՂ -ից տեղահանվածների բնակարանային ապահովման պետական աջակցության ծրագիր',
    },
  },
  difficultSituation: {
    label: 'Կյանքի դժվարին իրավիճակ',
    subMenu: {
      kindergartenGraduates: 'Մանկատան շրջանավարտների աջակցության ծրագիր',
      urgentNeed: 'Հրատապ կարիքի հաշվառում',
      monetarySupport: 'Դրամական աջակցություն ԼՂ բռնի տեղահանվածներին',
      badCredit: 'Անհուսալի վարկերի միջոցառում',
      functionality: 'Անձի ֆունկցիոնալության գնահատում',
      personalAssistant: 'Անձնական օգնականների ծրագիր',
      funeralAllowance: 'Թաղման նպաստ',
      npast: 'Անապահովության գնահատում և ծառայություններ',
      support: 'Աջակցություն կարիքին',
    },
  },
  pension: {
    label: 'Կենսաթոշակ',
    subMenu: {
      pensionRecalculation: 'Կենսաթոշակի վերահաշվարկի դիմում',
    },
  },
  supportNeed: 'Աջակցություն կարիքին',
};

export const menuTypes = [
  {
    id: 1,
    label: 'ԵՐԵԽԱՅԻ ԾՆՈՒՆդ',
    icon: Baby,
  },
  {
    id: 2,
    label: 'ԱՇԽԱՏԱՆՔ ԵՎ ԿԱՐԻեՐԱ',
    icon: Work,
  },
  {
    id: 3,
    label: 'ՆՈՐ ԲՆԱԿԱՐԱՆ',
    icon: Apartment,
  },
  {
    id: 4,
    label: 'կՅԱՆՔԻ դԺՎԱՐԻՆ ԻՐԱՎԻՃԱԿ',
    icon: DifficultSituation,
  },
  {
    id: 5,
    label: 'ԿԵՆՍԱԹՈՇԱԿ',
    icon: Pension,
  },
];

export const isLegalTypes = [
  {
    id: 1,
    label: 'Աջակցություն կարիքին',
    icon: DifficultSituation,
    link: PATHS.OFFERING_SUPPORT,
  },
];

export const subMenuTypes = [
  {
    id: 1,
    label: 'Մանկատան շրջանավարտների աջակցության ծրագիր',
    icon: Graduate,
    link: 'https://og.socservice.am/frontend/web/index.php?r=site%2Flogin',
  },
  {
    id: 2,
    label: 'Հրատապ կարիքի հաշվառում',
    icon: UrgentNeed,
    link: PATHS.RAPID_NEED,
  },
  {
    id: 3,
    label: 'դրամական աջակցություն ԼՂ բռնի տեղահանվածներին',
    icon: Fourteen,
    link: PATHS.SUPPORT_APPLICATIONS,
  },
  {
    id: 4,
    label: 'Անհուսալի վարկերի միջոցառում',
    icon: BadCredit,
    link: PATHS.APPLICATION_BAD_CREDIT,
  },
  {
    id: 5,
    label: 'Անձի ֆունկցիոնալության գնահատում',
    icon: Disability,
    link: 'https://e-disability.am/home',
  },
  {
    id: 6,
    label: 'Անձնական օգնականների ծրագիր',
    icon: PersonalAssistant,
    link: 'https://pa.socservice.am/frontend/web/index.php?r=site%2Flogin',
  },
  {
    id: 7,
    label: 'թաղման նպաստ',
    icon: Allowance,
    link: 'https://online.ssa.am/index.php?r=site%2Flogin',
  },
  {
    id: 8,
    label: 'Աջակցություն կարիքին',
    icon: DifficultSituation,
    link: PATHS.OFFERING_SUPPORT,
  },
  {
    id: 9,
    label: (
      <>
        Անապահովության գնահատում եվ ծառայություններ <br /> <br /> Շուտով
      </>
    ),
    icon: Npast,
    link: PATHS.APPLICATION,
  },
];

export const fourteenMenu = [
  {
    id: 1,
    label: 'դրամական աջակցություն ԼՂ բռնի տեղահանվածներին',
    icon: Fourteen,
    link: 'https://rent.socservice.am',
  },
  {
    id: 2,
    label: 'ԼՂ բռնի տեղահանվածների դրամական աջակցության դիմումների կարգավիճակի ստուգում',
    icon: SupportApplications,
    link: 'https://rent.socservice.am/verify',
  },
];

export const birthChild = [
  {
    id: 1,
    label: 'Երեխայի ծննդյան միանվագ նպաստ',
    icon: BirthChild,
    link: 'https://online.ssa.am/index.php?r=site%2Flogin',
  },
  {
    id: 2,
    label: 'Մինչեվ 2 տարեկան երեխայի նպաստ',
    icon: ChildBenefit,
    link: 'https://online.ssa.am/index.php?r=site%2Flogin',
  },
  {
    id: 3,
    label: '3-րդ եվ հաջորդ երեխայի դրամական աջակցություն',
    icon: ChildSupport,
    link: 'https://online.ssa.am/index.php?r=site%2Flogin',
  },
  {
    id: 4,
    label: 'Վճարային բանկի ընտրություն',
    icon: BankChoice,
    link: 'https://online.ssa.am/index.php?r=site%2Flogin',
  },
];

export const workEmployment = [
  {
    id: 1,
    label: 'Անձի ֆունկցիոնալությունը գնահատող մասնագետների ներգրավման ծրագիր',
    icon: PersonalFunctionality,
    link: 'https://vdimum.socservice.am/frontend/web/index.php?r=site%2Flogin',
  },
  {
    id: 2,
    label: 'Աշխատանք եվ զբաղվածություն',
    icon: WorkEmployment,
    link: 'https://e-work.am',
  },
];

export const newApartment = [
  {
    id: 1,
    label: 'ԼՂ -ից տեղահանվածների բնակարանային ապահովման պետական աջակցության ծրագիր',
    icon: Apartment,
    link: 'https://housing.socservice.am',
  },
];

export const pension = [
  {
    id: 1,
    label: 'Կենսաթոշակի վերահաշվարկի դիմում',
    icon: Pension,
    link: 'https://online.ssa.am/index.php?r=site%2Flogin',
  },
];
