import { Menu as MenuComponent } from 'antd';
import { DownSquareOutlined } from '@ant-design/icons';
import { Link, useMatches, useNavigate } from 'react-router-dom';
import { COLORS, PATHS } from '../../helpers/constants';
import styled from 'styled-components';
import { useAuth } from '../../context/auth-context';
import { menuText } from '../../pages/services/constant';
import { LINKS } from './constants';
import { useGetNidUrl } from 'api/auth/use-get-nid-url';
import { URL_LOGIN_NID_USER } from 'api/auth/constants';
import { Title } from 'components/typography';

const getItem = (label, key, children, type) => {
  return {
    key,
    children,
    label,
    type,
  };
};

const SubMenu = styled(MenuComponent)`
  .ant-menu-submenu {
    max-height: 185px;
    overflow-y: auto;
    direction: rtl; /* Moves scrollbar to the left */
    scrollbar-width: thin;
    scrollbar-color: #020731 #fff0;
  }

  .ant-menu-submenu > .ant-menu-submenu-title {
    height: fit-content;
    color: #ffffff;
    direction: ltr; /* Ensures text remains left-aligned */
  }

  .ant-menu-title-content {
    line-height: 1.6;
    margin-bottom: 15px;
  }

  /* Webkit (Chrome, Safari) */
  .ant-menu-submenu::-webkit-scrollbar {
    width: 8px;
  }

  .ant-menu-submenu::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 50px;
  }

  .ant-menu-submenu::-webkit-scrollbar-thumb:hover {
    background: #555;
  }

  .ant-menu-submenu::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  /* Fix text alignment */
  .ant-menu-submenu * {
    direction: ltr; /* Resets text alignment */
    text-align: left;
  }
`;

const CustomCardTitle = styled(Title)`
  @media (max-width: 550px) {
    font-size: 18px !important;
  }

  @media (max-width: 450px) {
    font-size: 17px !important;
  }
`;

const StyledSpan = styled.span`
  color: white;
`;

const white = {
  color: COLORS.PRIMARY.WHITE,
};

export const ServicesNavbar = () => {
  const match = useMatches();
  const { user } = useAuth();
  const url = useGetNidUrl(URL_LOGIN_NID_USER);
  const navigate = useNavigate();

  const items = [
    getItem(<CustomCardTitle color={COLORS.PRIMARY.WHITE}>Ֆիզիկական անձանց</CustomCardTitle>, '0.5'),
    getItem(<StyledSpan title={menuText.birthChild.label}>{menuText.birthChild.label}</StyledSpan>, '1', [
      getItem(
        <a
          href={LINKS.SSA}
          target="_blank"
          rel="noopener noreferrer"
          title={menuText.birthChild.subMenu.allowance}
          style={white}
        >
          {menuText.birthChild.subMenu.allowance}
        </a>,
        '2'
      ),
      getItem(
        <a
          href={LINKS.SSA}
          target="_blank"
          rel="noopener noreferrer"
          title={menuText.birthChild.subMenu.childUpTwoYears}
          style={white}
        >
          {menuText.birthChild.subMenu.childUpTwoYears}
        </a>,
        '3'
      ),
      getItem(
        <a
          href={LINKS.SSA}
          target="_blank"
          rel="noopener noreferrer"
          title={menuText.birthChild.subMenu.financialSupport}
          style={white}
        >
          {menuText.birthChild.subMenu.financialSupport}
        </a>,
        '4'
      ),
      getItem(
        <a
          href={LINKS.SSA}
          target="_blank"
          rel="noopener noreferrer"
          title={menuText.birthChild.subMenu.paymentBank}
          style={white}
        >
          {menuText.birthChild.subMenu.paymentBank}
        </a>,
        '5'
      ),
    ]),
    getItem(<StyledSpan title={menuText.workCareer.label}>{menuText.workCareer.label}</StyledSpan>, '6', [
      getItem(
        <a
          href={LINKS.VDIMUM}
          target="_blank"
          rel="noopener noreferrer"
          title={menuText.workCareer.subMenu.programInvolvement}
          style={white}
        >
          {menuText.workCareer.subMenu.programInvolvement}
        </a>,
        '7'
      ),
      getItem(
        <a
          href={LINKS.E_WORK}
          target="_blank"
          rel="noopener noreferrer"
          to={user ? PATHS.APPLICATION : PATHS.APPLY_FOR_THE_PROGRAM}
          title={menuText.workCareer.subMenu.workEmployment}
          style={white}
        >
          {menuText.workCareer.subMenu.workEmployment}
        </a>,
        '8'
      ),
    ]),
    getItem(<StyledSpan title={menuText.newApartment.label}>{menuText.newApartment.label}</StyledSpan>, '9', [
      getItem(
        <a
          href={LINKS.HOUSING}
          target="_blank"
          rel="noopener noreferrer"
          title={menuText.newApartment.subMenu.displacedPeople}
          style={white}
        >
          {menuText.newApartment.subMenu.displacedPeople}
        </a>,
        '10'
      ),
    ]),
    getItem(
      <StyledSpan title={menuText.difficultSituation.label}>{menuText.difficultSituation.label}</StyledSpan>,
      '11',
      [
        getItem(
          <a
            href={LINKS.OG}
            target="_blank"
            rel="noopener noreferrer"
            title={menuText.difficultSituation.subMenu.kindergartenGraduates}
            style={white}
          >
            {menuText.difficultSituation.subMenu.kindergartenGraduates}
          </a>,
          '12'
        ),
        getItem(
          <Link
            to={user ? PATHS.APPLICATION : PATHS.SIGN_IN}
            onClick={() => localStorage.setItem('rapidNeed', true)}
            title={menuText.difficultSituation.subMenu.urgentNeed}
            style={white}
          >
            {menuText.difficultSituation.subMenu.urgentNeed}
          </Link>,
          '13'
        ),
        getItem(
          <div
            onClick={() => navigate(PATHS.SUPPORT_APPLICATIONS, { state: { fourteen: true } })}
            title={menuText.difficultSituation.subMenu.monetarySupport}
            style={white}
          >
            {menuText.difficultSituation.subMenu.monetarySupport}
          </div>,
          '14'
        ),
        getItem(
          <div
            onClick={() => {
              window.location.href = url.data;
              localStorage.setItem('creditApplication', true);
            }}
            title={menuText.difficultSituation.subMenu.badCredit}
            style={white}
          >
            {menuText.difficultSituation.subMenu.badCredit}
          </div>,
          '15'
        ),
        getItem(
          <a
            href={LINKS.E_DISABILITY}
            target="_blank"
            rel="noopener noreferrer"
            title={menuText.difficultSituation.subMenu.functionality}
            style={white}
          >
            {menuText.difficultSituation.subMenu.functionality}
          </a>,
          '16'
        ),
        getItem(
          <a
            href={LINKS.PERSONAL_ASISTANT}
            target="_blank"
            rel="noopener noreferrer"
            to={user ? PATHS.APPLICATION : PATHS.APPLY_FOR_THE_PROGRAM}
            title={menuText.difficultSituation.subMenu.personalAssistant}
            style={white}
          >
            {menuText.difficultSituation.subMenu.personalAssistant}
          </a>,
          '17'
        ),
        getItem(
          <a
            href={LINKS.SSA}
            target="_blank"
            rel="noopener noreferrer"
            title={menuText.difficultSituation.subMenu.funeralAllowance}
            style={white}
          >
            {menuText.difficultSituation.subMenu.funeralAllowance}
          </a>,
          '18'
        ),
        getItem(
          <div
            onClick={() => {
              navigate(PATHS.SIGN_IN)
              localStorage.setItem('supportNeed', true)
            }}
            title={menuText.difficultSituation.subMenu.support}
            style={white}
          >
            {menuText.difficultSituation.subMenu.support}
          </div>,
          '19'
        ),
        getItem(
          <div title={menuText.difficultSituation.subMenu.npast} style={{ color: '#c6cbd3' }}>
            {menuText.difficultSituation.subMenu.npast}
          </div>,
          '20'
        ),
      ]
    ),
    getItem(<StyledSpan title={menuText.pension.label}>{menuText.pension.label}</StyledSpan>, '21', [
      getItem(
        <a
          href={LINKS.SSA}
          target="_blank"
          rel="noopener noreferrer"
          title={menuText.pension.subMenu.pensionRecalculation}
          style={white}
        >
          {menuText.pension.subMenu.pensionRecalculation}
        </a>,
        '22'
      ),
    ]),
    getItem(<CustomCardTitle color={COLORS.PRIMARY.WHITE}>Իրավաբանական անձանց</CustomCardTitle>, '22.5'),
    getItem(
      <div
        onClick={() => {
          window.location.href = url.data;
        }}
        title={menuText.supportNeed}
        style={white}
      >
        {menuText.supportNeed}
      </div>,
      '23'
    ),
  ];

  return (
    <>
      <SubMenu
        style={{
          width: '100%',
          height: 'fit-content',
          background: 'none',
          color: COLORS.PRIMARY.WHITE,
        }}
        expandIcon={<DownSquareOutlined style={{ color: '#ffffff' }} />}
        selectedKeys={match.map((item) => item.pathname)}
        mode="inline"
        items={items}
      />
    </>
  );
};
