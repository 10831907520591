import { Layout } from 'antd';
import styled from 'styled-components';
import { screenSize } from '../../../helpers/constants';

const { Content } = Layout;

const Wrapper = styled(Content)`
  /* padding: 32px; */

  @media only screen and (max-width: 1300px) {
    padding: 0;
  }
  @media only screen and (max-width: ${screenSize.lg}) {
    padding: 0;
  }
`;

export const DefaultUserContent = ({ children }) => <Wrapper id="main">{children}</Wrapper>;
