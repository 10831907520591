import { Radio } from 'antd';
import dayjs from 'dayjs';
import { URL_CITIZEN_INFO, URL_LEGAL_REPRESENTITIVE_INFO } from '../../../api/application/constants';
import { useDataForApplication } from '../../../api/application/use-get-data-for-application';
import { COLORS, PATHS } from '../../../helpers/constants';
import { VerticalSpace } from '../../space/vertical-space';
import { SecondaryText } from '../../typography';
import { Form } from '../form';
import { FormItem } from '../form-item';
import { APPLICATION_FOR_HIMSELF, LEGAL_REPRESENTITIVE } from '../../../pages/application/constants';
import { useNavigate, useOutletContext } from 'react-router-dom';
import { errorMessage } from '../../../helpers/utils';
import { citizenData } from './utils';

export const ApplicationFor = () => {
  const context = useOutletContext();
  const form = Form.useFormInstance();
  const fillingApplication = Form.useWatch('fillingApplication');
  const profileData = form.getFieldValue('profileData');
  const navigate = useNavigate();

  useDataForApplication(
    URL_CITIZEN_INFO,
    {},
    {
      enabled: fillingApplication === APPLICATION_FOR_HIMSELF && !context.isEdit,
      onSuccess: (data) => {
        form.setFieldsValue({
          id: data.id,
          profileId: data.profileId,
          profileData: null,
        });
      },
      onError: errorMessage,
    }
  );

  useDataForApplication(
    URL_LEGAL_REPRESENTITIVE_INFO,
    {},
    {
      enabled: !!(
        (fillingApplication === LEGAL_REPRESENTITIVE && !context.isEdit) ||
        (fillingApplication === LEGAL_REPRESENTITIVE && context.isEdit && !profileData)
      ),
      onSuccess: ({ birthdate, profileId, id, ...data }) => {
        form.setFieldsValue({
          id,
          profileId,
          profileData: {
            legalRepresentative: {
              ...data,
              fname: data.firstName,
              lname: data.lastName,
              birthdate: dayjs(birthdate),
              profileData: citizenData,
            },
          },
        });
      },
      onError: (err) => {
        errorMessage(err);
        navigate(PATHS.SERVICES);
      },
    }
  );

  return (
    <FormItem name="fillingApplication" rules={[{ required: true }]}>
      <Radio.Group>
        <VerticalSpace>
          <Radio value={1}>
            <SecondaryText color={COLORS.SECONDARY.OIL}>Ինձ համար</SecondaryText>
          </Radio>
          <Radio value={2}>
            <SecondaryText color={COLORS.SECONDARY.OIL}>Այլ անձի համար</SecondaryText>
          </Radio>
        </VerticalSpace>
      </Radio.Group>
    </FormItem>
  );
};
