import { Col, Layout, Row } from 'antd';
import styled from 'styled-components';
// import { PageTitle } from '../typography/title';
import { GerbSection } from './gerb-logo';
// import { SsaSection } from './ssa-logo';
import { COLORS, PATHS, screenSize } from '../../helpers/constants';
import { PageTitle } from 'components/typography/title';
import { useAuth } from 'context/auth-context';
import { useLocation, useNavigate } from 'react-router-dom';
import { Button } from 'components/button';

const { Header: HeaderComponent } = Layout;

const HeaderStyle = styled(HeaderComponent)`
  background-color: ${({ user, pathname }) =>
    !user && pathname !== PATHS.SIGN_IN && pathname !== PATHS.ONE_TIME_PASSWORD
      ? COLORS.SECONDARY.OIL
      : COLORS.PRIMARY.WHITE};
  color: ${COLORS.PRIMARY.WHITE};
  height: 110px;
  margin-left: auto;
  margin-right: auto;

  @media only screen and (max-width: ${screenSize.lg}) {
    padding: 0;
    height: 220px;
  }
  @media only screen and (max-width: ${screenSize.sm}) {
    padding: 0;
    height: 200px;
  }
`;

// eslint-disable-next-line no-unused-vars
export const Header = ({ nonreliable }) => {
  const { user } = useAuth();
  const location = useLocation();
  const navigate = useNavigate();
  return (
    <HeaderStyle user={user} pathname={location.pathname}>
      <Row align={'middle'} justify={'center'}>
        <Col md={24} lg={2}>
          <Button
            onClick={() => navigate(user ? PATHS.SERVICES : PATHS.ROOT)}
            style={{ background: 'none', border: 'none', height: 'fit-content', padding: 0, width: 'fit-content' }}
            aria-label="Աշխատանքի եվ սոցիալական հարցերի նախարարություն"
          >
            <GerbSection />
          </Button>
        </Col>
        <Col md={24} lg={22} style={{ textAlign: 'center', lineHeight: '35px' }}>
          <PageTitle nonreliable={nonreliable} />
        </Col>
        {/* <SsaSection /> */}
      </Row>
    </HeaderStyle>
  );
};
