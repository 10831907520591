import { Layout } from 'antd';
import styled from 'styled-components';

const { Sider } = Layout;

export const MainSider = styled(Sider)`
  background-color: #0b847f;
  padding: 114px 0px;

  .ant-layout-sider-zero-width-trigger {
    top: ${(props) => (props?.user ? '10px' : '60px')};
  }

  @media (max-width: 786px) {
    position: absolute !important;
    z-index: 99;
    height: 160vh;
    top: 303px;
    /* padding: 0; */

    .ant-layout-sider-zero-width-trigger {
      top: 255px;
      left: 0;
      position: fixed;
    }
  }
`;
