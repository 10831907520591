import { Col, Layout, Row } from 'antd';
import { ContentWrapper } from '../../components/layouts/content-wrapper';
import { Title } from '../../components/typography';
import { ServicesSider } from '../../components/layouts/service-sider';
import { COLORS, ESOCIAL_DOMAIN, PATHS } from '../../helpers/constants';
import { ServicesDepartment } from './services-department';
import { useAuth } from '../../context/auth-context';
import { Card } from '../../components/card';
import { ManagePage } from '../../components/layouts/manage-page';
import { useState } from 'react';
import { useGetNidUrl } from 'api/auth/use-get-nid-url';
import { URL_LOGIN_NID_USER } from 'api/auth/constants';
import styled from 'styled-components';
import { isLegalTypes, menuTypes } from './constant';
import { useNavigate } from 'react-router-dom';

const CustomCard = styled(Card)`
  ${({ isGray }) =>
    isGray &&
    `
    .ant-card-actions {
      background: #e1e1e1 !important;
    }
  `}
`;

const CustomCardTitle = styled(Title)`
  @media (max-width: 570px) {
    text-align: center;
  }

  @media (max-width: 500px) {
    font-size: 16px !important;
  }
`;

export const MyServices = () => {
  const { user } = useAuth();
  const navigate = useNavigate();
  const [isLegal, setIsLegal] = useState(false);
  const url = useGetNidUrl(URL_LOGIN_NID_USER);
  const currentDomain = window.location.hostname;
  const isCurrentDomainESocial = ESOCIAL_DOMAIN.includes(currentDomain);
  const midLogin = localStorage.getItem('midLogin');

  const menuItems = isLegal ? isLegalTypes : menuTypes;

  return (
    <>
      {!user && (
        <>
          <ManagePage />
          {/*<ServicesHeader />*/}
        </>
      )}

      <Layout>
        {!user && <ServicesSider />}
        <Layout style={{ position: 'relative' }}>
          <ServicesDepartment setIsLegal={setIsLegal} />
          <ContentWrapper style={{ background: COLORS.SECONDARY.ALICE_BLUE }}>
            <Row gutter={[16, 16]}>
              {menuItems &&
                menuItems?.map((item) => {
                  if (item.id === 12 && isCurrentDomainESocial) {
                    return null;
                  }

                  return (
                    <Col
                      xs={24}
                      sm={24}
                      md={24}
                      lg={24}
                      xl={24}
                      xxl={12}
                      style={{ margin: 0, width: 500 }}
                      key={item?.id}
                    >
                      <CustomCard
                        bodyStyle={{
                          cursor: 'pointer',
                          boxShadow: '2px 2px 4px 2px #00000040',
                          minHeight: 180,
                        }}
                        role="button"
                        tabIndex={0}
                        aria-label={item.label}
                        onClick={() => {
                          if (isLegal && !midLogin) {
                            window.location.href = url.data;
                          } else if (isLegal && midLogin) {
                            navigate(item.link);
                          } else {
                            navigate(!user ? PATHS.SUB_SERVICES : PATHS.PRIVATE_SUBSERVICES, {
                              state: { type: item?.id },
                            });
                          }
                        }}
                      >
                        <Row align={'middle'} gutter={30}>
                          <Col xs={24} sm={8} md={8} lg={8} style={{ display: 'flex', justifyContent: 'center' }}>
                            <img src={item.icon} alt="icon" />
                          </Col>
                          <Col xs={24} sm={16} md={16} lg={16}>
                            <CustomCardTitle color={'#3B3F5F'} style={{ fontSize: 20, textTransform: 'uppercase' }}>
                              {item.label}
                            </CustomCardTitle>
                          </Col>
                        </Row>
                      </CustomCard>
                    </Col>
                  );
                })}
            </Row>
          </ContentWrapper>
        </Layout>
      </Layout>
    </>
  );
};
