import { Radio, Space } from 'antd';
import { FormSectionCard } from '../../../card/form-section-card';
import { FormItem } from '../../form-item';
import { SecondaryText } from '../../../typography';
import { COLORS, DOCUMENT_TYPES } from '../../../../helpers/constants';
import { EducationTypesSelect } from '../../../select/education-types-select';
import { Form } from '../../form';
import { useApplication } from 'context/applicaton-context';
import { useApplicationForText } from 'hooks/application/use-application-for-text';
import { DocumentUpload } from 'components/upload/upload-documets';
import { URL_GET_EDUCATIONAL_ORGANIZATIONS } from 'api/file/constants';
import { useGetData } from 'api/dictionary/use-get-data';

export const EducationForm = ({ formItemEducation = {}, parent = [], isPersonal = false, fieldKey }) => {
  const isStadying = Form.useWatch([...parent, ...formItemEducation.isStudying.name]);
  const { submittedData, cityzenData } = useApplication();
  const applicationForText = useApplicationForText('եմ', 'է');
  const { data: organizations } = useGetData(URL_GET_EDUCATIONAL_ORGANIZATIONS);

  return (
    <FormSectionCard
      title={`Սովորում ${isPersonal ? applicationForText : 'է'} առկա ուսուցման համակարգում`}
      style={{ display: 'grid' }}
    >
      <FormItem rules={[{ required: true }]} {...formItemEducation.isStudying}>
        <Radio.Group>
          <Space>
            <Radio value={true}>
              <SecondaryText color={COLORS.PRIMARY.BLUE}>Այո</SecondaryText>
            </Radio>
            <Radio value={false}>
              <SecondaryText color={COLORS.PRIMARY.BLUE}>Ոչ</SecondaryText>
            </Radio>
          </Space>
        </Radio.Group>
      </FormItem>
      {isStadying === true && (
        <>
          <FormItem
            label="Ընտրել տեսակը"
            rules={[{ required: true }]}
            {...formItemEducation.educationalInstitution}
            required={isStadying === true}
          >
            <EducationTypesSelect />
          </FormItem>

          <DocumentUpload
            tempApplicationId={submittedData.tempApplicationId}
            attachedDocumentTypeId={DOCUMENT_TYPES.EDUCATION}
            attachedDocumentName={'Տեղեկանք ուսման վայրից'}
            infoText="Խնդրում ենք կցել տվյալ կիսամյակում ուսումնական հաստատության կողմից տրամադրված տեղեկանքը:"
            userData={cityzenData?.citizenInfo}
            organizations={organizations}
            propName={isPersonal ? ['educationFiles'] : [`educationFiles${fieldKey}`]}
            checksumName={formItemEducation.studyFilesChecksum.name}
            parent={parent}
          />
        </>
      )}
    </FormSectionCard>
  );
};
