import { Radio, Space } from 'antd';
import { FormSectionCard } from '../../../card/form-section-card';
import { FormItem } from '../../form-item';
import { SecondaryText } from '../../../typography';
import { COLORS, DOCUMENT_TYPES } from '../../../../helpers/constants';

import { useApplication } from 'context/applicaton-context';
import { Form } from 'components/form/form';
import { useApplicationForText } from 'hooks/application/use-application-for-text';
import { DocumentUpload } from 'components/upload/upload-documets';

export const MilitaryForm = ({ formItemMilitary = {}, parent = [], isPersonal = false, fieldKey }) => {
  const { submittedData, cityzenData } = useApplication();
  const isMilitary = Form.useWatch([...parent, ...formItemMilitary.isMilitaryService.name]);
  const applicationForText = useApplicationForText('եմ', 'է');

  return (
    <FormSectionCard
      title={`Ժամկետային զինծառայող ${isPersonal ? applicationForText : 'է'}`}
      style={{ display: 'grid' }}
    >
      <FormItem rules={[{ required: true }]} {...formItemMilitary.isMilitaryService}>
        <Radio.Group>
          <Space>
            <Radio value={true}>
              <SecondaryText color={COLORS.PRIMARY.BLUE}>Այո</SecondaryText>
            </Radio>
            <Radio value={false}>
              <SecondaryText color={COLORS.PRIMARY.BLUE}>Ոչ</SecondaryText>
            </Radio>
          </Space>
        </Radio.Group>
      </FormItem>
      {isMilitary === true && (
        <DocumentUpload
          tempApplicationId={submittedData.tempApplicationId}
          attachedDocumentTypeId={DOCUMENT_TYPES.MILITARY}
          attachedDocumentName={'Տեղեկանք զին. ծառայության մասին'}
          infoText="Խնդրում ենք կցել զինկոմիսարիատի կողմից տրամադրված տեղեկանքը։"
          userData={cityzenData?.citizenInfo}
          propName={isPersonal ? 'militaryFiles' : `militaryFiles${fieldKey}`}
          checksumName={formItemMilitary.militaryServiceFilesChecksum.name}
          parent={parent}
        />
      )}
    </FormSectionCard>
  );
};
