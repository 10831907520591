/* eslint-disable no-unused-vars */
import { useNavigate } from 'react-router-dom';
import { message, Modal } from 'antd';
import { useState } from 'react';
import { APPLICATION_STATUSES, COLORS, PATHS } from '../../../helpers/constants';
import { Actions } from '../../../components/button/actions';
import {
  URL_CLONE_APPLICATION,
  URL_DELETE_INCOMPLETE_APPLICATION,
  URL_POST_APPLICATION,
} from '../../../api/application/constants';
import { usePostApplication } from '../../../api/account/use-post-application';
import { errorMessage } from '../../../helpers/utils';
import dayjs from 'dayjs';
import { APPLICATION_TYPES } from '../constants';
import { AppealModal } from '../modals/appeal-modal';
import { useSaveApplication } from '../../../api/application/use-save-application';
import { CheckCircleOutlined } from '@ant-design/icons';

export const useColumns = (type, currentPage = 1, pageSize = 10, setIsLoadingTable) => {
  const [appealApplicationId, setAppealApplicationId] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isLoading, setSubmitLoading] = useState(false);

  const navigate = useNavigate();
  const { mutate: cloneMutate } = usePostApplication(URL_CLONE_APPLICATION, {
    onSuccess: () => {
      message.success(`Դիմումը հաջողությամբ կլոնավորվեց`);
    },
    onError: errorMessage,
  });
  const { mutate: deleteMutate } = usePostApplication(URL_DELETE_INCOMPLETE_APPLICATION, {
    onSuccess: () => {
      message.success(`Դիմումը հաջողությամբ ջնջվեց`);
    },
    onError: errorMessage,
  });

  const handleDelete = (id) => {
    deleteMutate({ tempApplicationId: id });
  };
  const handleClone = (id) => {
    cloneMutate({ applicationId: id });
  };
  const handleAppeal = (id) => {
    // eslint-disable-next-line no-console
    console.log('id', id);
    setAppealApplicationId(id);
    setIsModalVisible(true);
  };
  const handleCloseModal = () => {
    setIsModalVisible(false);
  };
  const getViewNavigationPath = (item, type) => {
    const { systemTypeId, applicationId, statusId } = item;

    switch (systemTypeId) {
      case 9:
        return PATHS.RAPID_NEED_VIEW.replace(':id', applicationId);

      case 10:
        return PATHS.OFFERING_SUPPORT_VIEW.replace(':id', applicationId);

      case 2:
        return PATHS.APPLICATION_NON_RELIABLE_VIEW.replace(':type', type).replace(':id', applicationId);
      default:
        // if (statusId === 0 && systemTypeId !== 2) {
        //   return PATHS.APPLICATIONS_INCOMPLETE_VIEW.replace(':type', type)
        //     .replace(':id', applicationId)
        //     .replace(':status', 'incomplete');
        // }
        return PATHS.APPLICATIONS_VIEW.replace(':type', type).replace(':id', applicationId);
    }
  };

  const { mutate: applicationSubmit } = useSaveApplication(URL_POST_APPLICATION, {
    onSuccess: (res) => {
      Modal.success({
        title: 'Շնորհակալություն',
        content: (
          <div style={{ fontSize: '16px' }} dangerouslySetInnerHTML={{ __html: res?.data?.notificationMessage }} />
        ),
        footer: false,
        closable: true,
        icon: <CheckCircleOutlined style={{ color: COLORS.PRIMARY.BLUE }} />,
        onCancel: () => location.reload(),
      });
      setSubmitLoading(false);
      setIsLoadingTable(false)
    },
    onError: (error) => {
      errorMessage(error);
      setSubmitLoading(false);
      setIsLoadingTable(false)
    },
  });

  const handleReconfirmApp = (ItemId) => {
    setSubmitLoading(true);
    setIsLoadingTable(true);
    applicationSubmit({
      tempApplicationId: ItemId
    })
  }

  return [
    {
      title: 'N',
      dataIndex: 'n',
      align: 'center',
      flex: 1,
      render: (_, __, index) => (currentPage - 1) * pageSize + index + 1,
    },
    {
      title: 'Դիմումի տեսակ',
      dataIndex: 'systemType',
      key: 'systemType',
      align: 'center',
      flex: 1,
    },
    ...(type !== APPLICATION_TYPES.DONOR_APPLICATION
      ? [
        {
          title: 'Դիմումի ենթատեսակ',
          dataIndex: 'applicationType',
          key: 'applicationType',
          align: 'center',
          flex: 1,
        },
      ]
      : []),
    {
      title: 'Դիմումի համար',
      dataIndex: 'number',
      key: 'number',
      align: 'center',
      flex: 1,
    },
    {
      title: 'Ներկայացման ամսաթիվ',
      dataIndex: 'createdDate',
      key: 'createdDate',
      align: 'center',
      flex: 1,
      sorter: (a, b) => dayjs(a.createdDate, 'DD/MM/YYYY').diff(dayjs(b.createdDate, 'DD/MM/YYYY')),
    },
    {
      title: 'Կարգավիճակ',
      dataIndex: 'status',
      key: 'status',
      align: 'center',
      flex: 1,
      filters: APPLICATION_STATUSES,
      onFilter: (value, record) => record.status === value,
    },
    ...(type !== APPLICATION_TYPES.DONOR_APPLICATION
      ? [
        {
          title: 'Կարգավիճակի ստացման ամսաթիվ',
          dataIndex: 'stateChangedDate',
          key: 'stateChangedDate',
          align: 'center',
          flex: 1,
          sorter: (a, b) => dayjs(a.stateChangedDate, 'DD/MM/YYYY').diff(dayjs(b.stateChangedDate, 'DD/MM/YYYY')),
        },
      ]
      : []),
    {
      title: '',
      key: 'operation',
      fixed: 'right',
      align: 'center',
      render: (item) => (
        <>
          {Array.isArray(item?.actions) && item.actions.length > 0 && (
            <Actions
              items={item}
              onView={() => navigate(getViewNavigationPath(item, type))}
              onEdit={() =>
                navigate(
                  item.systemTypeId === 9
                    ? `${PATHS.RAPID_NEED_EDIT.replace(':id', item.applicationId)}`
                    : item.systemTypeId === 10
                      ? `${PATHS.OFFERING_SUPPORT_EDIT.replace(':id', item.applicationId)}`
                      : `${PATHS.APPLICATIONS_UPDATE.replace(':id', item.applicationId)}`
                )
              }
              onPrint={() =>
                window.open(
                  `${(item.systemTypeId === 2 ? PATHS.NON_RELIABLE_AGREEMENT_PRINT : PATHS.APPLICATIONS_PRINT).replace(
                    ':id',
                    item.applicationId
                  )}`,
                  '_blank',
                  'noopener,noreferrer'
                )
              }
              onDecision={() =>
                window.open(
                  `${PATHS.DECISION_PRINT.replace(':id', item.applicationId)}`,
                  '_blank',
                  'noopener,noreferrer'
                )
              }
              onDelete={() => handleDelete(item.applicationId)}
              onClone={() => handleClone(item.applicationId)}
              onAppeal={() => handleAppeal(item.applicationId)}
              onReconfirm={() => handleReconfirmApp(item.applicationId)}
              isLoading={isLoading}
            />
          )}
          <AppealModal visible={isModalVisible} appealApplicationId={appealApplicationId} onClose={handleCloseModal} />
        </>
      ),
    },
  ];
};
