import { Menu, Tooltip } from 'antd';
import styled from 'styled-components';
import { Link, useMatches } from 'react-router-dom';

import { COMPANY_MENU_TYPES, MENU_TYPES } from './constants';
import { useAuth } from 'context/auth-context';
import { COLORS } from 'helpers/constants';

const SideMenu = styled(Menu)`
  border-radius: 4px;
  color: ${COLORS.SECONDARY.OIL};

  &.ant-menu {
    li.ant-menu-item {
      line-height: normal;

      &-selected {
        background: #ffffff;
        border: 1px solid #0b847f;
        background: #ffffff;
        border: 0.56px solid #e2def9;
        box-shadow: -28px 22px 45px rgba(27, 29, 66, 0.139423);
        a {
          color: ${COLORS.SECONDARY.OIL};
          background: #ffffff;
        }
        &:after {
          opacity: 0;
        }
      }
      &-active {
        background-color: #ffffff;
        border: 1px solid #ffffff;
        a {
          color: ${COLORS.SECONDARY.OIL};
        }
      }
    }
  }
`;
export const UserNavbar = () => {
  const match = useMatches();
  const {
    user: { isLegal },
  } = useAuth();
  return (
    <SideMenu
      mode="inline"
      style={{
        borderRight: 0,
        background: 'transparent',
        padding: '28px 43px',
      }}
      selectedKeys={match.map((item) => item.pathname)}
      items={Object.entries(isLegal ? COMPANY_MENU_TYPES : MENU_TYPES).map(([, menu]) => {
        return {
          key: menu.url,
          label: (
            <Tooltip title={menu?.label}>
              <Link to={menu.url}>{menu.label}</Link>
            </Tooltip>
          ),
          icon: menu.icon,
        };
      })}
    />
  );
};
