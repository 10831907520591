import { Col, Modal, Row } from 'antd';
import { VerticalSpace } from 'components/space/vertical-space';

import { ReactComponent as EID } from './icons/eID.svg';
import { ReactComponent as MID } from './icons/mID.svg';
import { URL_LOGIN_NID_USER } from '../../../api/auth/constants';
import { useGetNidUrl } from '../../../api/auth/use-get-nid-url';
import { Button } from 'components/button';
import { ArrowRightOutlined } from '@ant-design/icons';
import { COLORS } from 'helpers/constants';
import icon from './icons/check-circle.svg';

export const Identification = () => {
  const url = useGetNidUrl(URL_LOGIN_NID_USER);

  // eslint-disable-next-line no-unused-vars
  const handleClick = async () => {
    Modal.info({
      icon: <img src={icon} alt="icon" style={{ marginRight: 5 }} />,
      title: 'Ուշադրություն',
      content: (
        <div>
          <p>Հարգելի օգտվող,</p>
          <p>
            Դուք վերահասցեագրվում եք «Ես եմ» ազգային նույնականացման հարթակ։ Մուտք գործելուց հետո հարթակն ինքնաշխատ
            եղանակով Ձեզ կվերադարձնի ՀՀ սոցիալական պաշտպանության ոլորտի դիմումների միասնական համակարգ։
          </p>
        </div>
      ),
      footer: (
        <Button
          type="primary"
          onClick={() => (window.location.href = url.data)}
          style={{ float: 'right', background: COLORS.PRIMARY.BLUE }}
        >
          Շարունակել <ArrowRightOutlined />
        </Button>
      ),
      width: '30%',
      closable: true,
    });
  };

  return (
    <VerticalSpace direction="horizontal" style={{ width: '100%', justifyContent: 'center' }}>
      <Row gutter={8}>
        <Col>
          <MID onClick={() => (window.location.href = url.data)} style={{ cursor: 'pointer' }} />{' '}
        </Col>
        <Col>
          <EID onClick={() => (window.location.href = url.data)} style={{ cursor: 'pointer' }} />
        </Col>
      </Row>
    </VerticalSpace>
  );
};
