export const getBase64 = (img, callback) => {
  const reader = new FileReader();
  reader.addEventListener('load', () => callback(reader.result));
  reader.readAsDataURL(img);
};

export const createFormData = (dataObject) => {
  const formData = new FormData();

  Object.entries(dataObject).forEach(([key, value]) => {
    if (value instanceof File) {
      // If the value is a File object, append it directly
      formData.append(key, value);
    } else if (typeof value === 'object' && value !== null) {
      // If the value is an object (like nested data), stringify it
      formData.append(key, JSON.stringify(value));
    } else {
      // For other types, append as is
      formData.append(key, value || '');
    }
  });

  return formData;
};
