import { ContentWrapper } from '../../components/layouts/content-wrapper';
import { Col, Layout, Modal, Row } from 'antd';
import { useNavigate, useOutletContext } from 'react-router-dom';
import { SecondaryTitle, SecondaryText } from '../../components/typography/text';
import Application from '../../icons/application.svg';
import { PATHS } from 'helpers/constants';
import { useEffect } from 'react';
import { useAuth } from 'context/auth-context';
import { useGetFfromBenefit } from 'api/benefit/use-get-dictionary';
import { URL_GET_NON_RELIABLE_VERIFY_ELIGIBILITY } from 'api/application/constants';
import { ServicesSider } from 'components/layouts/service-sider';
import styled, { css } from 'styled-components';
import backgroundImage from '../../icons/background.svg';

const applications = [
  {
    id: 1,
    // link: PATHS.APPLICATION_BAD_CREDIT,
    link: PATHS.APPLY_FOR_THE_PROGRAM,
    text: 'Անհուսալի վարկերի միջոցառում',
    // otherSite: PATHS.APPLICATION_BAD_CREDIT,
    otherSite: PATHS.APPLY_FOR_THE_PROGRAM,
  },
  {
    id: 2,
    link: PATHS.RAPID_NEED,
    text: 'Ցանկանում եմ դիմել արագ արձագանքման միասնական հարթակի աջակցությանը',
    otherSite: 'https://stage-urrp.e-social.am/rapid-need',
  },
];

export const BackgroundLayout = styled(Layout)`
  ${(props) =>
  props.inner
    ? css`
          background: transparent;
        `
    : css`
           /* background-image: url(${backgroundImage}); */
           background-size: cover;
           background-position: center;
           background-repeat: no-repeat;
           background-color: #ffffff;
        `}
`;

const fixedContentWrapperHeight = '150px'; // Set a fixed height for the content wrapper

export const DifficultSituationApplicationSelection = () => {
  const user = useAuth();
  const [, setHeaderText] = useOutletContext() || [{}, () => {}];
  const navigate = useNavigate();
  const { data } = useGetFfromBenefit(
    URL_GET_NON_RELIABLE_VERIFY_ELIGIBILITY,
    { profileId: user?.user?.profileId },
    { enabled: !!user?.user }
  );

  useEffect(() => {
    setHeaderText?.({
      text: 'Հայտնվել եմ կյանքի դժվարին իրավիճակում',
    });
    window.scrollTo(0, 0);
  }, [setHeaderText]);

  const handleClick = (app) => {
    if (app.id === 1 && !data?.isSuccess && !!user?.user) {
      Modal.warning({
        title: 'Ուշադրություն',
        onCancel: () => navigate(PATHS.DIFFICULT_SITUATION_APPLICATION_SELECTION),
        onOk: () => navigate(PATHS.DIFFICULT_SITUATION_APPLICATION_SELECTION),
        okText: 'Լավ',
        content: <div dangerouslySetInnerHTML={{ __html: data?.notificationMessage }} />,
      });
    } else if (app.id === 2) {
      if (user?.user) {
        navigate(app.link);
      } else {
        window.open(app.otherSite, '_blank');
      }
    } else {
      navigate(app.link);
    }
  };

  return (
    <BackgroundLayout>
      <Row>
        {!user.user && (
          <Col xs={0} md={6} xxl={6}>
            <ServicesSider />
          </Col>
        )}
        <Col xs={24} md={user.user ? 24 : 18} xxl={user.user ? 24 : 18}>
          <Row>
            <Col xs={{ span: 22, offset: 1 }} md={{ span: 20, offset: 2 }} xxl={{ span: 18, offset: 3 }}>
              <SecondaryTitle style={{ fontSize: 18, marginTop: 20 }}>Ընտրեք անհրաժեշտ դիմումի տեսակը</SecondaryTitle>
              <Row gutter={[16, 16]}>
                {applications.map((application, index) => (
                  <Col key={index} xs={24} md={12} xxl={8}>
                    <ContentWrapper
                      style={{
                        padding: '60px 23px',
                        height: fixedContentWrapperHeight,
                        display: 'flex',
                        alignItems: 'center',
                        cursor: 'pointer',
                      }}
                      onClick={() => handleClick(application)}
                    >
                      <Row gutter={6} style={{ flex: 1, alignItems: 'center' }}>
                        <Col span={4}>
                          <img src={Application} alt="application" style={{ maxWidth: '100%' }} />
                        </Col>
                        <Col span={20}>
                          <SecondaryText color="#13002E">{application.text}</SecondaryText>
                        </Col>
                      </Row>
                    </ContentWrapper>
                  </Col>
                ))}
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
    </BackgroundLayout>
  );
};

