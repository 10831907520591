import { DOCUMENT_TYPES } from 'helpers/constants';
import { VerticalSpace } from '../../../space/vertical-space';
import { FormRequiredInfo } from '../../../typography/form-required-info';
import { Info } from '../../../typography/info';

import { Form } from '../../form';
import { LegalRepresentative } from './legal-representative';
import { OtherPersonType } from './other-person-type';
import { DocumentUpload } from 'components/upload/upload-documets';

export const ApplicationNotForMe = () => {
  const applicationFor = Form.useWatch('fillingApplication');
  const profileData = Form.useWatch(['profileData', 'legalRepresentative'], { preserve: true });
  const form = Form.useFormInstance();
  return applicationFor === 2 ? (
    <VerticalSpace>
      <OtherPersonType />
      <Info text="Խնդրում ենք կցել տեղականք" />
      <DocumentUpload
        userData={profileData}
        attachedDocumentTypeId={DOCUMENT_TYPES.LEGAL_REPRESENTITIVE}
        attachedDocumentName={'Տեղեկանք օրինական ներկայացուցիչ լինելու մասին'}
        tempApplicationId={form.getFieldValue('tempApplicationId')}
        propName="legalFiles"
        checksumName={['profileData', 'legalFilesChecksum']}
        infoText={'Խնդրում ենք կցել տեղական ինքնակառավարման մարմնի կողմից տրամադրված տեղեկանք'}
      />
      <FormRequiredInfo />
      <LegalRepresentative />
    </VerticalSpace>
  ) : (
    <></>
  );
};
