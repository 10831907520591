import { useEffect, useState } from 'react';
import { Col, Form, Row } from 'antd';
import { Input } from '../../components/input';
import { Datepicker } from '../../components/datepicker';
import { phonePattern } from '../../helpers/constants';
import { URL_ACCOUNT_VERIFY_EMAIL } from '../../api/application/constants';
import { useGetUserData } from '../../api/Users/use-get-user-data';

export const ApplicantPersonalData = ({ emailUser = null }) => {
  const form = Form.useFormInstance()
  const [email, setEmail] = useState(null)
  const [emailError, setEmailError] = useState("")

  useEffect(() => {
    if (form) {
      const ssn = form.getFieldValue('ssn')
      if(emailUser !== undefined || emailUser !== '') {
        setEmail({
          email: emailUser,
          ssn: ssn
        })
      }
    }
  }, [form, emailUser]);

  useGetUserData(
    URL_ACCOUNT_VERIFY_EMAIL,
    email,
    {
      enabled: !!email?.email,
      onSuccess: (result) => {
        if(!result) {
          setEmailError("Այս էլ․ հասցեով օգտատեր արդեն գոյություն ունի");
          form.setFields([
            {
              name: "email",
              errors: ["Այս էլ․ հասցեով օգտատեր արդեն գոյություն ունի"],
            },
          ])
        } else {
          setEmailError("");
        }
      },
    }
  )

  const verifyEmail = (e) => {
    let value = e.target.value;
    setEmailError("");
    const ssn = form.getFieldValue('ssn')
    if(ssn !== undefined && ssn !== '') {
      setEmail({
        email: value,
        ssn: ssn
      })
    }
  }

  return (
    <>
      <Row gutter={16} justify={'center'}>
        <Form.Item name="profileId" hidden></Form.Item>
        <Col xs={24} sm={24} md={12} lg={12} xl={8}>
          <Form.Item label="Անուն" name="firstName">
            <Input size="middle" disabled />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={12} lg={12} xl={8}>
          <Form.Item label="Ազգանուն" name="lastName">
            <Input size="middle" disabled />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={12} lg={12} xl={8}>
          <Form.Item label="Հայրանուն" name="patronymicName">
            <Input size="middle" disabled />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16} justify={'center'}>
        <Col xs={24} sm={24} md={12} lg={12} xl={8}>
          <Form.Item label="ՀԾՀ/ՀԾՀ չունենալու մասին տեղեկանք" name="ssn">
            <Input size="middle" disabled />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={12} lg={12} xl={8}>
          <Form.Item label="Սեռ" name="sex">
            <Input size="middle" disabled />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={12} lg={12} xl={8}>
          <Form.Item label="Ծննդյան ամսաթիվ " name="birthdate">
            <Datepicker style={{ width: '100%' }} size="middle" disabled />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16} justify={'center'}>
        <Col xs={24} sm={24} md={12} lg={12}>
          <Form.Item
            label={'Հեռախոսի համար'}
            name="phoneNumber"
            rules={[
              { required: true, pattern: phonePattern, message: 'Ֆորմատը սխալ է {+37400000000}' },
            ]}
          >
            <Input placeholder={'+37400000000'} size="middle" />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={12} lg={12}>
          <Form.Item label={"Էլեկտրոնային փոստի հասցե"}
             rules={[
               { required: true },
               { type: "email", message: "Տեղեկատվությունը սխալ է լրացված" },
               {
                 pattern: /^[a-zA-Z0-9@._-]*$/,
                 message: "Էլ․ հասցեն պետք է պարունակի միայն լատինատառ նիշեր",
               },
               // eslint-disable-next-line no-unused-vars
               ({ getFieldValue }) => ({
                 validator() {
                   if (emailError) {
                     return Promise.reject(new Error(emailError));
                   }
                   return Promise.resolve();
                 },
               }),
            ]}
            name="email"
          >
            <Input
              size="middle"
              onChange={(e) => verifyEmail(e)}
            />
          </Form.Item>
          <Form.Item hidden name={'documentType'} />
          <Form.Item hidden name={'documentNumber'} />
        </Col>
      </Row>
    </>
  );
};
