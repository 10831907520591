import { useLocation, useNavigate } from 'react-router-dom';
import { ReactComponent as Gerb } from '../../icons/coat.svg';
import { ReactComponent as BlackGerb } from '../../icons/blackCoat.svg';
import styled from 'styled-components';
import { PATHS } from 'helpers/constants';
import { useAuth } from 'context/auth-context';

const MlsaLogo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 20px;

  span {
    font-weight: 700;
    font-size: 8px;
    line-height: 11px;
    text-align: center;
    text-transform: uppercase;
  }
`;

export const GerbSection = () => {
  const { user } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  return (
    <MlsaLogo>
      {!user && location.pathname !== PATHS.SIGN_IN && location.pathname !== PATHS.ONE_TIME_PASSWORD ? (
        <Gerb
          style={{ cursor: 'pointer', marginLeft: 15 }}
          onClick={() => (user ? navigate(PATHS.SERVICES) : navigate(PATHS.ROOT))}
        />
      ) : (
        <BlackGerb
          style={{ cursor: 'pointer', marginLeft: 15 }}
          onClick={() => (user ? navigate(PATHS.SERVICES) : navigate(PATHS.ROOT))}
        />
      )}
    </MlsaLogo>
  );
};
