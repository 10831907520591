import { Row, Col, Typography } from 'antd';
import dayjs from 'dayjs';
import { CheckCircleOutlined } from '@ant-design/icons';

export const CitizenInfo = ({ data }) => {
  return (
    <div>
      <>
        {data?.legalRepresentative && (
          <>
            <Row justify="center" gutter={16}>
              <Col span={24}>
                <Typography.Title level={5} style={{ textAlign: 'center', color: '#3B3F5F' }}>
                  Օրինական ներկայացուցչի տվյալներ
                </Typography.Title>
              </Col>
            </Row>
            <Row justify="space-between">
              <Col>ՀԾՀ համար/ՀԾՀ չունենալու մասին տեղեկանքի համար</Col>
              <Col>{data?.legalRepresentative?.ssn}</Col>
            </Row>
            <Row justify="space-between">
              <Col>Անուն, ազգանուն, հայրանուն</Col>
              <Col>
                {data?.legalRepresentative?.firstName} {data?.legalRepresentative?.lastName}{' '}
                {data?.legalRepresentative?.patronymicName}
              </Col>
            </Row>
            <Row justify="space-between">
              <Col>Ծննդյան ամսաթիվ</Col>
              <Col>
                {data?.legalRepresentative?.birthdate &&
                  dayjs(data?.legalRepresentative?.birthdate).format('DD.MM.YYYY')}{' '}
              </Col>
            </Row>
            <Row justify="space-between">
              <Col>Սեռ</Col>
              <Col>{data?.legalRepresentative?.gender}</Col>
            </Row>
            <Row justify="space-between">
              <Col>Հեռախոս</Col>
              <Col>{data?.legalRepresentative?.phoneNumber}</Col>
            </Row>
            <Row justify="space-between">
              <Col>Էլեկտրոնային փոստ</Col>
              <Col>{data?.legalRepresentative?.email}</Col>
            </Row>
          </>
        )}
        <Row justify="center" gutter={16}>
          <Col span={24}>
            <Typography.Title level={5} style={{ textAlign: 'center', color: '#3B3F5F' }}>
              Դիմումատուի տվյալներ
            </Typography.Title>
          </Col>
        </Row>
        <Row justify="space-between">
          <Col>ՀԾՀ համար/ՀԾՀ չունենալու մասին տեղեկանքի համար</Col>
          <Col>{data?.citizenInfo?.ssn}</Col>
        </Row>
        <Row justify="space-between">
          <Col>Անուն, ազգանուն, հայրանուն</Col>
          <Col>
            {data?.citizenInfo?.firstName} {data?.citizenInfo?.lastName} {data?.citizenInfo?.patronymicName}
          </Col>
        </Row>
        <Row justify="space-between">
          <Col>Ծննդյան ամսաթիվ</Col>
          <Col>{data?.citizenInfo?.birthdate && dayjs(data?.citizenInfo?.birthdate).format('DD.MM.YYYY')} </Col>
        </Row>
        <Row justify="space-between">
          <Col>ԱՀՓ համար</Col>
          <Col>{data?.citizenInfo?.documentNumber} </Col>
        </Row>
        <Row justify="space-between">
          <Col span={12}>Հաշվառման հասցե</Col>
          <Col span={12}>
            {data?.registrationAddress?.region} {data?.registrationAddress?.community}{' '}
            {data?.registrationAddress?.settlement} {data?.registrationAddress?.street}{' '}
            {data?.registrationAddress?.building}
          </Col>
        </Row>
        <Row justify="space-between">
          <Col span={12}>Բնակվում է /եմ իր հաշվառման հասցեում</Col>
          <Col>{data?.IsActualAddressMatch === true ? 'Այո' : 'Ոչ'}</Col>
        </Row>
        <Row justify="space-between">
          <Col span={12}>Փաստացի բնակության հասցե</Col>
          <Col span={12}>
            {data?.actualAddress?.region} {data?.actualAddress?.community} {data?.actualAddress?.settlement}{' '}
            {data?.actualAddress?.street} {data?.actualAddress?.building}
          </Col>
        </Row>
        <Row justify="space-between">
          <Col>Սեռ</Col>
          <Col>{data?.citizenInfo?.gender}</Col>
        </Row>
        <Row justify="space-between">
          <Col>Հեռախոս</Col>
          <Col>{data?.citizenInfo?.phoneNumber}</Col>
        </Row>
        <Row justify="space-between">
          <Col>Էլեկտրոնային փոստ</Col>
          <Col>{data?.citizenInfo?.email}</Col>
        </Row>
        <Row justify="space-between">
          <Col>Բաժանորդային համար՝</Col>
        </Row>
        <Row>
          <Col span={23} push={1}>
            <Row justify="space-between">
              <Col>Գազպրոմ Արմենիա</Col>
              <Col>{data?.gasAccount}</Col>
            </Row>
            <Row justify="space-between">
              <Col>ՀԵՑ</Col>
              <Col>{data?.electricityAccount}</Col>
            </Row>
            <Row justify="space-between">
              <Col>Վեոլիա ջուր</Col>
              <Col>{data?.waterAccount}</Col>
            </Row>
          </Col>
        </Row>
        <Row justify="space-between">
          <Col>Սովորում է/եմ առկա ուսուցման համակարգում</Col>
          <Col>{data?.isStudying === true ? 'Այո' : 'Ոչ'}</Col>
        </Row>
        <Row justify="space-between">
          <Col>Հանդիսանում է/եմ զինծառայող</Col>
          <Col>{data?.isMilitaryService === true ? 'Այո' : 'Ոչ'}</Col>
        </Row>
        <Row justify="space-between">
          <Col>Անասնատեսակի առկայություն</Col>
          <Col>{data?.animals?.length ? `${data?.animals?.length} հատ` : ''}</Col>
        </Row>
        <Row justify="space-between">
          <Col>Վճարման եղանակի ընտրություն</Col>
        </Row>
        <Row>
          <Col span={23} push={1}>
            <Row justify="space-between">
              <Col>Վճարման տեսակ</Col>
              <Col>{data?.paymentMethod}</Col>
            </Row>
            <Row justify="space-between">
              <Col>Նախընտրած բանկ</Col>
              <Col>{data?.bank}</Col>
            </Row>
          </Col>
        </Row>
        <Row justify="space-between">
          <Col>Ծանուցումները նախընտրում եմ/ենք ստանալ SMS-ով</Col>
          <Col>{data?.isNotifyBySMS === true ? 'Այո' : 'Ոչ'}</Col>
        </Row>
      </>
      <Row style={{ paddingTop: '20px', width: '100%' }}>
        {/* <Row justify="space-between">
                    <Col>Ծանուցումը նախընտրում է/եմ ստանալ</Col>
                    <Col>{data?.preferredGetNotified}</Col>
                </Row> */}
        <p>
          <CheckCircleOutlined style={{ color: '#3B3F5F' }} />
          Հավաստիացնում եմ, որ ներկայացրած տվյալները ճիշտ են{' '}
        </p>
      </Row>
    </div>
  );
};
