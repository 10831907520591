import {useGetPhotoForUser} from "../../api/account/use-get-photo-for-user";
import {URL_GET_PHOTO_FOR_USER} from "../../api/benefit/constants";
import {COLORS} from "../../helpers/constants";
import { ReactComponent as MyPhoto } from './images/profile.svg';


export const MyProfile = () => {
    const {data} = useGetPhotoForUser(URL_GET_PHOTO_FOR_USER);

    return (
            data?.photo ?
                <img src={"data:image/png;base64," + data.photo}
                     style={{objectFit: 'contain', height: 140, width: 140, borderRadius: 140, border: `2px solid ${COLORS.SECONDARY.OIL}`}} alt="Profile"/>
                :
                <MyPhoto style={{ height: 140, width: 140, borderRadius: 140, border: `2px solid ${COLORS.SECONDARY.OIL}` }} />
    )
}