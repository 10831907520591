import { createContext, useContext, useState } from 'react';

const FileContext = createContext();

export const FileProvider = ({ children }) => {
  const [formState, setFormState] = useState({});

  return <FileContext.Provider value={{ formState, setFormState }}>{children}</FileContext.Provider>;
};

export const useFileContext = () => {
  const context = useContext(FileContext);
  if (!context) {
    throw new Error('useFileContext must be used within a FormProvider');
  }
  return context;
};
